import { Link } from 'react-router-dom';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';


const ProductPhotos = (props) => {

    const { id, photo, countPhoto, handleScrollToTop } = props.props

    const handleProductLink = (id_product) => {
        handleScrollToTop();
        localStorage.setItem('lastPosScroll', window.scrollY)
    }
    return (
        <Swiper
            pagination={{
                dynamicBullets: true,
            }}
            modules={[Pagination]}
            className="h-[100%] w-[100%]"
        >
            {Array.from({ length: countPhoto }, (_, index) => (
                <SwiperSlide key={index} className="rounded-[20px]">
                    <Link to={`/product/${id}`} className="" onClick={() => handleProductLink(id)}>
                        <img
                            src={`${process.env.PUBLIC_URL}/img/products/${photo}/${photo}_${index + 1}.jpg`}
                            alt={`model ${index + 1}`}
                            className="rounded-t-[20px] block object-cover h-[100%] w-[100%]"
                        />
                    </Link>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default ProductPhotos;
