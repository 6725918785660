
import axios from "axios"
export const addToCart = (product, cart, setCart, idSize, user) => {
    product.choice = product.size.split(', ')[idSize]
    product.count = 1
    const newCart = [...cart, product]
    if (user) {
        axios.post(`https://poghosty.ru/api/poghosty-authorizationAddOrRemoveCart.php`, { login: user.login, cart: JSON.stringify(newCart) })
            .then(response => {
                if (response.data['error'] ? true : false) {
                    console.log(response.data['error'])
                } else {
                    setCart(newCart)
                    localStorage.setItem('cart', JSON.stringify(newCart))
                }
            })
            .catch(error => {
                console.log(error);
            });
    } else {
        setCart(newCart)
        localStorage.setItem('cart', JSON.stringify(newCart))
    }
}
export const removeFromCart = (product, cart, setCart, user) => {
    const newCart = cart.filter(item => item.id !== product.id)
    if (user) {
        axios.post(`https://poghosty.ru/api/poghosty-authorizationAddOrRemoveCart.php`, { login: user.login, cart: JSON.stringify(newCart) })
            .then(response => {
                if (response.data['error'] ? true : false) {
                    console.log(response.data['error'])
                } else {
                    setCart(newCart)
                    localStorage.setItem('cart', JSON.stringify(newCart))
                }
            })
            .catch(error => {
                console.log(error);
            });
    } else {
        setCart(newCart)
        localStorage.setItem('cart', JSON.stringify(newCart))
    }
}
export const addToFavorites = (product, favorites, setFavorites, user) => {
    const newFavorites = [...favorites, product]
    if (user) {
        axios.post(`https://poghosty.ru/api/poghosty-authorizationAddOrRemoveFavorites.php`, { login: user.login, favorites: JSON.stringify(newFavorites) })
            .then(response => {
                if (response.data['error'] ? true : false) {
                    console.log(response.data['error'])
                } else {
                    setFavorites(newFavorites)
                    localStorage.setItem('favorites', JSON.stringify(newFavorites))
                }
            })
            .catch(error => {
                console.log(error);
            });
    } else {
        setFavorites(newFavorites)
        localStorage.setItem('favorites', JSON.stringify(newFavorites))
    }
}
export const removeFromFavorites = (product, favorites, setFavorites, user) => {
    const newFavorites = favorites.filter(item => item.id !== product.id)
    if (user) {
        axios.post(`https://poghosty.ru/api/poghosty-authorizationAddOrRemoveFavorites.php`, { login: user.login, favorites: JSON.stringify(newFavorites) })
            .then(response => {
                if (response.data['error'] ? true : false) {
                    console.log(response.data['error'])
                } else {
                    setFavorites(newFavorites)
                    localStorage.setItem('favorites', JSON.stringify(newFavorites))
                }
            })
            .catch(error => {
                console.log(error);
            });
    } else {
        setFavorites(newFavorites)
        localStorage.setItem('favorites', JSON.stringify(newFavorites))
    }
} 