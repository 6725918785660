import { useContext, useEffect, useState } from "react";
import { FcLike } from "react-icons/fc";
import { GrFavorite } from "react-icons/gr";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { MdAddCircle } from "react-icons/md";
import { IoMdRemoveCircle } from "react-icons/io";
import { addToCart, removeFromCart, addToFavorites, removeFromFavorites } from "../../utils/utils";
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import axios from "axios";
import { Pagination, Navigation } from 'swiper/modules';

import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Context } from "../../App";
import { useParams } from "react-router";
const Product = () => {
    const { user, cart, setCart, favorites, setFavorites } = useContext(Context)
    const [product, setProduct] = useState({})

    const location = useLocation();
    const navigate = useNavigate();
    const [idItem, setIdItem] = useState('')
    const [idSize, setIdSize] = useState('')
    const [errorSizeId, setErrorSizeId] = useState('')
    const [isOpenSizesGrid, setIsOpenSizesGrid] = useState(false)
    const [openImg, setOpenImg] = useState(false)

    const sizesGrid = product && product.rowsTable ? JSON.parse(product.rowsTable) : []
    const { id } = useParams()
    useEffect(() => {
        axios.get(`https://poghosty.ru/api/poghosty-getProduct.php`, {
            params: { id: id }
        })
            .then(response => {
                setProduct(response.data['product'])
            })
            .catch(error => {
                console.log(error);
            });
    }, [id])
    const handleGoBack = () => {
        if (location.state && location.state.prevPath) {
            navigate(location.state.prevPath, { replace: true });
        } else {
            navigate(-1);
        }
    };

    // Скролл в начало при нажатии на страницу
    const handleScrollToTop = () => {
        setTimeout(() => window.scrollTo({ top: +JSON.parse(localStorage.getItem('lastPosScroll')), left: 0, behavior: 'smooth' }), 500)
    };
    const inCart = (id) => {
        return cart && cart.some(el => el.id === id)
    }
    const inCartAndSize = (id, size) => {
        return cart && cart.some(el => el.id === id && el.choice === size)
    }
    const inFavorites = (id) => {
        return favorites && favorites.some(el => el.id === id)
    }
    const handleAddOrRemoveFromFavorites = (product, i) => {
        if (inFavorites(product.id)) {
            removeFromFavorites(product, favorites, setFavorites, user)
        } else {
            addToFavorites(product, favorites, setFavorites, user)
        }
    }
    const handleAddOrRemoveFromCart = (product, i) => {
        if (inCart(product.id)) {
            removeFromCart(product, cart, setCart, user)
        } else {
            if (idSize !== '' && idItem === product.id) {
                addToCart(product, cart, setCart, idSize, user)
            } else {
                setErrorSizeId(product.id)
            }
        }
    }
    return (
        <div className="bg-transparent dark:bg-gray-800 py-4" onClick={(e) => {
            // if (e.target.id !== 'size') { setIdItem(''); setIdSize('') }
            if (e.target.id !== 'cart') { setErrorSizeId('') }
            // else { setErrorSize(''); setErrorSizeId('') }
        }}>
            <Link to='#' className="border border-white text-black bg-white p-2  rounded-md text-nowrap inline-flex items-center" onClick={() => { handleGoBack(); handleScrollToTop() }}><MdOutlineKeyboardBackspace className="text-[20px] text-black" /><b>Назад</b></Link>
            {product && <div className="max-w-6xl md:px-4 px-2 sm:px-0 lg:px-0 h-full">
                <div className="flex flex-col md:flex-row mt-4">
                    <div className="md:flex-1 mr-4 h-full md:w-1/2 w-full">

                        {
                            openImg ? <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-[9999] bg-black/70" onClick={(e) => { if (e.target !== e.currentTarget && e.target !== document.querySelector('.swiper-button-next') && e.target !== document.querySelector('.swiper-button-prev')) setOpenImg(!openImg) }}>
                                <Swiper
                                    pagination={{
                                        type: 'fraction',
                                    }}
                                    navigation={true}
                                    modules={[Pagination, Navigation]}
                                    className="max-w-[100vw] max-h-[100vh]"
                                >
                                    {Array.from({ length: product.countPhoto }, (_, index) => (
                                        <SwiperSlide key={index} className="rounded-[20px] flex justify-center z-[9999]">
                                            <img src={`${process.env.PUBLIC_URL}/img/products/${product.photo}/${product.photo}_${index + 1}.jpg`} alt={`${product.photo}`} className={` rounded-lg h-[90vh] w-auto object-cover`} onClick={() => setOpenImg(!openImg)}></img>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div> :
                                <Swiper
                                    pagination={{
                                        type: 'fraction',
                                    }}
                                    navigation={true}
                                    modules={[Pagination, Navigation]}
                                    className="w-full h-full text-white"
                                >
                                    {Array.from({ length: product.countPhoto }, (_, index) => (
                                        <SwiperSlide key={index} className="rounded-[20px]">
                                            <img src={`${process.env.PUBLIC_URL}/img/products/${product.photo}/${product.photo}_${index + 1}.jpg`} alt={`${product.photo}`} className={`rounded-lg block object-cover h-[100%] w-[100%]`} onClick={() => setOpenImg(!openImg)}></img>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                        }

                    </div>
                    <div className="md:flex-1 rounded-lg bg-white p-8">
                        <h2 className="text-2xl font-bold text-gray-800 dark:text-white mb-2">{product.name}</h2>
                        <p className="text-gray-600 text-xl dark:text-gray-300 mb-4">
                            {product.title}
                        </p>
                        <h1 className="text-gray-600 dark:text-gray-300 text-sm mb-4">
                            Артикул: <b>{product.article}</b>
                        </h1>

                        <div className="mb-4 border-l-2 pl-2 border-gray-300">
                            <span className="font-bold text-gray-700 dark:text-gray-300">Выбрать размер:</span>
                            <div className="flex items-center mt-2">
                                {product.size && product.size.split(', ').map((size, id) => {
                                    return <button id="size" key={id} className={`border border-gray-200 ${inCartAndSize(product.id, size) || (idSize === id && idItem === product.id) ? 'bg-emerald-400' : 'bg-gray-200 hover:bg-gray-300 dark:hover:bg-gray-700'} ${errorSizeId === product.id ? `shadow-[0_0px_17px_5px_rgba(255,0,0,0.16)]` : ''} dark:bg-gray-700 text-black dark:text-white py-2 px-4 rounded-full font-bold mr-2`} onClick={(e) => { if (inCartAndSize(product.id, size)) { setIdItem(''); setIdSize('') } else { setIdItem(product.id); setIdSize(id); setErrorSizeId('') } }}>{size}</button>
                                })}
                                {errorSizeId !== '' && <p id='errorSize' className="text-[14px] text-nowrap text-red-600">Выберите размер</p>}
                            </div>
                        </div>
                        <button className="text-white border bg-black dark:hover:bg-gray-700 hover:bg-gray-800 w-full text-left rounded-md mb-4 p-2 flex justify-start items-center" onClick={() => setIsOpenSizesGrid(!isOpenSizesGrid)}>
                            {isOpenSizesGrid ? <IoMdRemoveCircle className="text-[24px] mr-[15px]" /> : <MdAddCircle className="text-[24px] mr-[15px]" />}
                            <p>Размерная сетка</p>
                        </button>
                        {<div className={`max-h-[0px] overflow-auto text-black w-full transition-all ease-in-out duration-500 ${isOpenSizesGrid ? `max-h-[1000px]` : ''} `}>
                            <table className="border border-black bg-gray-200">
                                {sizesGrid.length > 0 && sizesGrid[0].length === 5 && <thead>
                                    <tr className="border-black">
                                        <th className="leading-5 border-r border-b border-black p-2">Размер</th>
                                        <th className="leading-5 border-r border-b border-black p-2">РФ размер</th>
                                        <th className="leading-5 border-r border-b border-black p-2">Обхват груди</th>
                                        <th className="leading-5 border-r border-b border-black p-2">Обхват талии</th>
                                        <th className="leading-5 border-black border-b p-2">Обхват бедер</th>
                                    </tr>
                                </thead>}
                                {sizesGrid.length > 0 && sizesGrid[0].length === 4 && <thead>
                                    <tr className="border-black">
                                        <th className="leading-5 border-r border-b border-black p-2">Размер</th>
                                        <th className="leading-5 border-r border-b border-black p-2">РФ размер</th>
                                        <th className="leading-5 border-r border-b border-black p-2">Обхват талии</th>
                                        <th className="leading-5 border-black border-b p-2">Обхват бедер</th>
                                    </tr>
                                </thead>}
                                <tbody>
                                    {sizesGrid.map((sizes, i) => {
                                        return <tr key={i} className="">
                                            {sizes.map((size, idx) => {
                                                return <td key={idx} className="text-center border-r border-black border-b">{size}</td>
                                            })}
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>}
                        <div className="md:flex-row flex-col flex justify-center items-center mb-4 bg-[#f5f5f7] rounded-md py-4">
                            <div className="md:mr-4 my-2 ">
                                <span className="text-black dark:text-gray-300 text-nowrap"><b>{product.cost} ₽</b></span>
                            </div>
                            <div className="px-2 my-2">
                                <button id="cart" className={` ${inCart(product.id) ? `bg-emerald-400` : `bg-black dark:hover:bg-gray-700 hover:bg-gray-800`} dark:bg-gray-600 text-white py-2 px-4 rounded-full font-bold h-[50px] w-full text-nowrap leading-4`} onClick={() => handleAddOrRemoveFromCart(product)}>{inCart(product.id) ? 'Добавлено в корзину!' : 'В корзину'}</button>
                            </div>
                            <div className=" px-2 my-2">
                                <button className="w-[100px] bg-gray-200 dark:bg-gray-700 dark:text-white py-2 px-4 rounded-full font-bold hover:bg-gray-300 dark:hover:bg-gray-600 flex h-[50px]" onClick={() => handleAddOrRemoveFromFavorites(product)}> {favorites.some(item => item.id === product.id) ? <FcLike className="text-red-600 mx-auto my-auto text-[29px]" /> : <GrFavorite className="text-black mx-auto my-auto text-[25px]" />}</button>
                            </div>
                        </div>
                        <div>
                            <span className="font-bold text-gray-700 dark:text-gray-300">Описание товара:</span>
                            <p className="text-gray-600 dark:text-gray-300 text-sm mt-2">
                                {product.description}
                            </p>
                        </div>
                    </div>
                </div>
            </div>}
        </div >
    );
};

export default Product;
