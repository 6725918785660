import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"
const FindProducts = (props) => {
    const { products, searchValue } = props.props
    const [searchedProducts, setSearchedProducts] = useState([]);
    useEffect(() => {
        const productsInSearch = products.filter((product) => {
            return product.name.toLowerCase().includes(searchValue.toLowerCase()) || product.first_level_season.toLowerCase().includes(searchValue.toLowerCase()) || product.article.toLowerCase().includes(searchValue.toLowerCase());
        });
        setSearchedProducts(productsInSearch)
    }, [searchValue, products]);
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    const handleProductLink = (id) => {
        localStorage.setItem('productId', id);
        localStorage.setItem('lastPosScroll', window.scrollY);
        handleScrollToTop()
    }
    return (
        <div className="h-full w-full pt-[10px]">
            {searchedProducts.length > 0 && searchedProducts.map((product) => (
                <Link to={`/product/${product.id}`} key={product.id} className=" mb-2 flex border-b border-gray-200 pb-[8px] items-center justify-center" onClick={() => handleProductLink(product.id)}>
                    <img src={`${process.env.PUBLIC_URL}/img/products/${product.photo}/${product.photo}_1.jpg`} alt={`${product.photo}`} className="w-[80px] h-auto rounded-[10px] mx-4" />
                    <div className="flex flex-col hover:bg-[#f5f5f5] w-full rounded-[10px] px-2 ">
                        <div className="flex">
                            <h2 className="pr-2"><b>{product.name}</b></h2>
                            <p className="italic"> {product.gender === "m" ? 'Мужчины' : product.gender === "w" ? 'Женщины' : ''}</p>
                        </div>
                        <p>{product.first_level_season}</p>
                        <p>Артикул: <b>{product.article}</b></p>
                    </div>
                </Link>
            ))
            }
            {
                !searchedProducts.length &&
                <div className="h-full w-full flex justify-center items-center">
                    <h2 className="sm:text-[24px] text-[18px] text-nowrap leading-[36px] md:p-4 p-2 text-gray-400">Упс... Ничего не найдено</h2>
                </div>
            }
        </div >
    );
};

export default FindProducts;
