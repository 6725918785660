import { useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { MdOutlineKeyboardBackspace } from "react-icons/md";
const Help = () => {
    const [target, setTarget] = useState('')
    const location = useLocation();
    const navigate = useNavigate();
    const handleGoBack = () => {
        if (location.state && location.state.prevPath) {
            navigate(location.state.prevPath, { replace: true });
        } else {
            navigate(-1);
        }
    };
    return (
        <>
            <Link to='#' className="border border-white text-black bg-white p-2 rounded-md text-nowrap inline-flex items-center mb-4" onClick={() => handleGoBack()}><MdOutlineKeyboardBackspace className="text-[20px] text-black" /><b>Назад</b></Link>
            <div className="text-black w-[100%] min-h-[100vh] p-8 rounded-md bg-white">
                <h1 className="text-center text-[36px] mb-6">Помощь</h1>

                <div className='mb-1 text-[24px]'>
                    <h3><b>Внимание!</b></h3>
                </div>
                <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '0.0') setTarget('0.0'); else { setTarget('') } }}>Спасибо за внимание :)</h4>
                <div className={`rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 max-h-[1500px] p-4 `}>
                    Не все позиции, представленные на сайте, есть в наличии. Наш менеджер сообщит Вам об этом. В данном случае, время ожидания заказа может продлиться до 5-7 дней, что является разумной ценой за высокое качество наших российских мастеров (я против пошива гигантских партий в Китае).
                </div>

                <div className='mb-1 text-[24px]'>
                    <h3><b>Доставка</b></h3>
                </div>

                <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '1.1') setTarget('1.1'); else { setTarget('') } }}>Способы доставки</h4>
                <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '1.1' ? `max-h-[1500px] p-4` : ''}`}>
                    <p>1. Самовывоз</p>
                    <p>2. Почта России</p>
                    <p>3. В пунктах выдачи СДЭК </p>
                    <h1 className='pt-[10px]'><strong>В случае самовывоза</strong></h1>
                    <p>Магазин poGHOSTy</p>
                    <p>г. Оренбург, ул. Салмышская, д. 41</p>
                    <p>тел. +7-925-317-47-14</p>
                </div>
                <div>
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '1.2') setTarget('1.2'); else { setTarget('') } }}>Сроки доставки</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '1.2' ? `max-h-[1500px] p-4` : ''}`}>
                        <p>Курьерская доставка по г. Оренбург от 1 до 2 дней;</p>
                        <p> Доставка почтой по России от 7 до 16 дней;</p>
                    </div>
                </div>
                <div >
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '1.3') setTarget('1.3'); else { setTarget('') } }}>Стоимость доставки</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '1.3' ? `max-h-[1500px] p-4` : ''}`}>
                        <p>Курьером от 320 руб.</p>
                        <p>Почтой от 590 руб.</p>
                        <p>Стоимость варьируется в зависимости от удаленности от г. Оренбург</p>
                    </div>
                </div>
                {/* <div >
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '1.4') setTarget('1.4'); else { setTarget('') } }}>Как быстро доставят мой заказ?</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '1.4' ? `max-h-[1500px] p-4` : ''}`}>
                        Сроки зависят от вашего местоположения.<br />
                        Курьерская доставка по СПб и Москве от 1 до 3 дней;<br />
                        Доставка почтой по России от 7 до 16 дней;<br />
                        Доставка почтой в СНГ от 10 до 20 дней;<br />
                        Доставка почтой зарубеж от 14 до 25 дней.</div>
                </div>
                <div >
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '1.5') setTarget('1.5'); else { setTarget('') } }}>Сколько стоит доставка?</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '1.5' ? `max-h-[1500px] p-4` : ''}`}>Курьером от 300 руб. <br />
                        Почтой от 550 руб.<br /><br />
                        Стоимость доставки рассчитывается автоматически при оформлении заказа,<br />
                        но может быть скорректирована вашим менеджером.</div>
                </div>
                <div >
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '1.6') setTarget('1.6'); else { setTarget('') } }}>Как отследить заказ?</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '1.6' ? `max-h-[1500px] p-4` : ''}`}>После отправки заказа номер отправления будет выслан по смс и почте, <br />
                        а также появится в вашем личном кабинете.</div>
                </div>
                <div className='mb-4'>
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '1.7') setTarget('1.7'); else { setTarget('') } }}>Как получить заказ, если я нахожусь в другой стране?</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '1.7' ? `max-h-[1500px] p-4` : ''}`}>Доставка осуществляется Почтой России и смежными с ней компаниями по всему миру.<br />
                        Например, в Америку доставка осуществляется USPS.
                    </div>
                </div> */}
                <div className='mb-1 text-[24px]'>
                    <h3><b>Оплата</b></h3>
                </div>

                <div>
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '2.1') setTarget('2.1'); else { setTarget('') } }}>Способы оплаты</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '2.1' ? `max-h-[1500px] p-4` : ''}`}>Оплатить заказ можно банковскими картами: Visa, Mastercard, Мир, Webmoney,<br />
                        <p>Оплатить можно как через сайт, следуйте инструкции. Или же переводом на СберБанк или Т-банк.</p>
                    </div>
                </div>
                <div>
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '2.2') setTarget('2.2'); else { setTarget('') } }}>Подтверждение оплаты</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '2.2' ? `max-h-[1500px] p-4` : ''}`}>
                        <p>После оформления заказа ожидайте до 2 рабочих дней</p>
                    </div>
                </div>
                {/* <div>
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '2.3') setTarget('2.3'); else { setTarget('') } }}>Как скоро после оплаты со мной свяжется менеджер?</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '2.3' ? `max-h-[1500px] p-4` : ''}`}>Наш менеджер свяжется с Вами в рабочее время с 11 до 22 (по мск). <br /><br />
                        Если заказ был оплачен в праздничные дни, то мы свяжемся <br />
                        с вами с небольшой задержкой. <br /><br />

                        Но, если ваш заказ срочный и требует доставки к определенному <br />
                        времени — звоните на наш номер <a href="tel:800-777-8127">8 800 777-81-27</a></div>
                </div>
                <div>
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '2.4') setTarget('2.4'); else { setTarget('') } }}>Я хочу оплатить заказ, но не нашел удобный для меня способ.</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '2.4' ? `max-h-[1500px] p-4` : ''}`}>Нет проблем! <br />Просто укажите удобный способ оплаты в ответном письме <br /> с номером вашего заказа.</div>
                </div>
                <div className='mb-4'>
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '2.5') setTarget('2.5'); else { setTarget('') } }}>Как получить товар, которого нет в наличии, ведь я уже за него заплатил!</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '2.5' ? `max-h-[1500px] p-4` : ''}`}>При проведении платежа на вашей карте резервируются средства, но не снимаются. Проведение платежа происходит только в момент подтверждения менеджером. И возврат происходит в течение 1-го рабочего дня максимум в таком случае.</div>
                </div> */}
                <div className='mb-1 text-[24px]'>
                    <h3><b>Возврат</b></h3>
                </div>

                <div>
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '3.1') setTarget('3.1'); else { setTarget('') } }} >Как я могу вернуть или заменить купленный товар?</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '3.1' ? `max-h-[1500px] p-4` : ''}`}>

                        <p>Вы можете вернуть изделие до его передачи в службу доставки или в случае выявленного брака – в течение 7 дней. Важное требование: сохранение всех бирок и отсутствие следов использования. Стоимость доставки качественного товара не возвращается. </p>
                    </div>
                </div>
                <div>
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '3.2') setTarget('3.2'); else { setTarget('') } }}>Сроки возврата</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '3.2' ? `max-h-[1500px] p-4` : ''}`}>
                        <p>После согласования решения о возврате средств, деньги будут возвращены в течение 2-10 рабочих дней.</p>
                    </div>
                </div>
                {/* <div>
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '3.3') setTarget('3.3'); else { setTarget('') } }}>Есть ли возврат по производственному браку?</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '3.3' ? `max-h-[1500px] p-4` : ''}`}>При обнаружении очевидного брака в полученном товаре, свяжитесь с нами по телефону
                        <a href="tel:800-777-8127">8 800 777-81-27</a> <br /> и опишите проблему. Наш менеджер сориентирует вас по дальнейшим действиям для возврата товара.


                    </div>
                </div>
                <div className='mb-4'>
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '3.4') setTarget('3.4'); else { setTarget('') } }}>Как скоро вернутся деньги после возврата?</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '3.4' ? `max-h-[1500px] p-4` : ''}`} >		После согласования решения о возврате средств, деньги будут возвращены тем же<br />
                        способом, каким была совершена оплата в течение 2-10 рабочих дней.
                    </div>
                </div> */}

                <div className='mb-1 text-[24px]'>
                    <h3><b>Безопасность</b></h3>
                </div>

                <div>
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '4.1') setTarget('4.1'); else { setTarget('') } }}>Политика конфиденциальности</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '4.1' ? `max-h-[1500px] p-4` : ''}`}>
                        <p>1.1. Мы не осуществляем сбор ваших персональных данных с использованием Сайта.</p>
                        <p>1.2. Все данные, собираемые на Сайте, предоставляются и принимаются в обезличенной форме (далее – «Обезличенные данные»).</p>
                        <p>1.3. Обезличенные данные включают следующие сведения, которые не позволяют вас идентифицировать:</p>
                        <p>1.3.1. Информацию, которую вы предоставляете о себе самостоятельно с использованием онлайн-форм и программных модулей Сайта, включая имя и номер телефона и/или адрес электронной почты.</p>
                        <p>1.3.2. Данные, которые передаются в обезличенном виде в автоматическом режиме в зависимости от настроек используемого вами программного обеспечения.</p>
                        <p>1.4. Администрация вправе устанавливать требования к составу Обезличенных данных Пользователя, которые собираются использованием Сайта.</p>
                        <p> 1.5. Если определенная информация не помечена как обязательная, ее предоставление или раскрытие осуществляется Пользователем на свое усмотрение. Одновременно вы даете информированное согласие на доступ неограниченного круга лиц к таким данным. Указанные данные становится общедоступными с момента предоставления и/или раскрытия в иной форме.</p>
                        <p>  1.6. Администрация не осуществляет проверку достоверности предоставляемых данных и наличия у Пользователя необходимого согласия на их обработку в соответствии с настоящей Политикой, полагая, что Пользователь действует добросовестно, осмотрительно и прилагает все необходимые усилия к поддержанию такой информации в актуальном состоянии и получению всех необходимых согласий на ее использование.</p>
                        <p> 1.7. Вы осознаете и принимаете возможность использования на Сайте программного обеспечения третьих лиц, в результате чего такие лица могут получать и передавать указанные в п.1.3 данные в обезличенном виде.</p>
                        <p> 1.8. Состав и условия сбора обезличенных данных с использованием программного обеспечения третьих лиц определяются непосредственно их правообладателями и могут включать:<br />
                            •	данные браузера (тип, версия, cookie);<br />
                            •	данные устройства и место его положения;<br />
                            •	данные операционной системы (тип, версия, разрешение экрана);<br />
                            •	данные запроса (время, источник перехода, IP-адрес).</p>
                        <p> 1.9. Администрация не несет ответственность за порядок использования Обезличенных данных Пользователя третьими лицами.</p>

                    </div>
                </div>
                <div className='mb-4'>
                    <h4 className=' underline-offset-4 underline cursor-pointer' onClick={() => { if (target !== '4.2') setTarget('4.2'); else { setTarget('') } }}>Политика безопасности</h4>
                    <div className={`max-h-[0px] rounded-[10px] bg-gray-200 overflow-hidden pl-4 transition-all ease-in-out duration-500 ${target === '4.2' ? `max-h-[1500px] p-4` : ''}`}>
                        <p>Бренд PoGHOSTy гарантирует, что предоставленная Вами информация не будет предоставлена третьему лицу, за исключением случаев, предусмотренных законодательством Российской Федерации.

                            В определенных обстоятельствах компания PoGHOSTy может попросить Вас предоставить личные данные при регистрации. Предоставленная информация используется при оформлении заказа в Интернет-магазине, отслеживании статуса заказа и общении с менеджерами.

                            Личные сведения можно изменить, обновить или удалить в любое время в разделе Личный кабинет.

                            Бренд PoGHOSTy с Вашего согласия может присылать на указанный при регистрации адрес электронной почты сообщения.

                            Нажимая кнопку оформления заказа, вы автоматически соглашаетесь с политикой безопасности.
                        </p>
                    </div>
                </div>
            </div>
        </>

    );
};

export default Help;
