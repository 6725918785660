import { FaQuestion } from "react-icons/fa6";
import { LuBox } from "react-icons/lu";
import { Link } from 'react-router-dom'
import { GoPerson } from "react-icons/go";
import { GiPoliceOfficerHead } from "react-icons/gi";
import { Context } from "../../App";
import { useContext } from "react";
const User = (props) => {
    const { openMyProfile, setOpenMyProfile } = props.props
    const { user, setUser, setCart, setFavorites } = useContext(Context)
    // Выход из аккаунта 
    const handleLogout = () => {
        localStorage.removeItem('user')
        setUser(null)
        setOpenMyProfile(!openMyProfile)
        localStorage.removeItem('cart')
        setCart([])
        localStorage.removeItem('favorites')
        setFavorites([])
    }
    const handleScrollToTop = () => {
        setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), 300)
    };
    return (
        <div className="text-black flex flex-col">
            <div className="bg-[#f5f5f7] p-2 flex justify-center items-center mb-2">
                <p className="">{`Здравствуйте, ${user[`name`] || ' '}!`}</p>
                {/* {user['name'][0].toUpperCase() + user['name'].slice(1).toLowerCase()} */}
            </div>
            <ul className="flex flex-col ">
                <li className="flex items-center p-2 sm:hover:bg-[#f5f5f7] px-4">
                    <LuBox size={'24px'} style={{ marginRight: '10px' }} />
                    <Link to={'/orders'} className="h-full w-full bg-transparent " onClick={() => handleScrollToTop()}>Мои заказы</Link>
                </li>
                <li className="flex items-center p-2 sm:hover:bg-[#f5f5f7] px-4">
                    <GoPerson size={'24px'} style={{ marginRight: '10px' }} />
                    <Link to={'/settings'} className="h-full w-full bg-transparent " onClick={() => handleScrollToTop()}>Настройка профиля</Link>
                </li>
                <li className="flex items-center p-2 sm:hover:bg-[#f5f5f7] px-4">
                    <FaQuestion size={'24px'} style={{ marginRight: '10px' }} />
                    <Link to={'/help'} className="h-full w-full bg-transparent " onClick={() => handleScrollToTop()}>Помощь</Link>
                </li>
                {user.name === 'adminPoghosty' && user.id === 62 && user.password === '$2y$10$C2sZwPM9hQ9fvQKus/YZnenap5gkoUFke8Qbg6AeJ0Y5qOnAcIxqO' && <li className="flex items-center p-2 sm:hover:bg-[#f5f5f7] px-4">
                    <GiPoliceOfficerHead size={'24px'} style={{ marginRight: '10px' }} />
                    <Link to={'/adminPoghosty'} className="h-full w-full bg-transparent ">Admin панель</Link>
                </li>}
            </ul>
            <div className="flex items-center justify-center h-[120px] w-[100%]">
                <Link to={'/'} className="border-2 p-2 border-transparent rounded-[20px] bg-red-500 hover:bg-opacity-70 text-white" onClick={() => { handleLogout() }}>Выйти из аккаунта</Link>
            </div>
        </div>
    );
};

export default User;
