import { Link } from 'react-router-dom';

const ProductInfo = (props) => {

    const { id, name, gender, cost, size, handleScrollToTop, idItem, idSize, setIdItem, setIdSize, errorSizeId, cart } = props.props
    // Ошибка

    const handleProductLink = (id) => {
        localStorage.setItem('lastPosScroll', window.scrollY);
        handleScrollToTop()
    }
    const inCartAndSize = (id, size) => {
        return cart && cart.some(el => el.id === id && +el.choice === +size)
    }
    const handleChangeSize = (e, id, i) => {
        if (cart.some(el => el.id === id)) {
            e.preventDefault()
        } else {
            setIdItem(id);
            setIdSize(i)
        }
    }

    return (
        <>
            <Link to={`/product/${id}`} className="" onClick={() => handleProductLink(id)}>

                <div className="flex">
                    <h1 className='pr-2'><b>{`${name}`}</b></h1>
                    <p className="italic">{`${gender === 'm' ? 'Мужчины' : gender === 'w' ? 'Женщины' : ''}`}</p>
                </div>

                <p>Цена: {cost} ₽</p>
            </Link>
            <div className="flex flex-col h-[55px]">
                <div className="pb-40px">Размеры: {
                    size.split(', ').map((size, i) => {
                        return (<button id='size' key={i} className={`${inCartAndSize(id, size) || (idItem === id && idSize === i)
                            ? `cursor-default hover:opacity-100 bg-emerald-400 text-white` : 'bg-slate-300 hover:opacity-70'} ${errorSizeId === id
                                ? `shadow-[0_0px_17px_5px_rgba(255,0,0,0.16)]` : ''} rounded-[4px] text-center w-[30px] h-[30px] px-1 mr-1`
                        } onClick={(e) => handleChangeSize(e, id, i)}>{size}</button>)
                    })}</div>
                {errorSizeId === id && <p id='errorSize' className="text-[14px] text-nowrap text-red-600">Выберите размер</p>}
            </div>
        </>
    );
};

export default ProductInfo;
