import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";
import { TiDelete } from "react-icons/ti";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { removeFromCart } from "../../utils/utils";
import { Context } from "../../App";
import axios from "axios";

const Cart = ({ setUser, getDate, products }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [idSelect, setIdSelect] = useState(null)
    const [clicked, setClicked] = useState(false)

    const [address, setAddress] = useState(false)
    // Корзина
    const { user, cart, setCart } = useContext(Context)
    const [userSettings, setUserSettings] = useState({
        name: user?.name || '',
        city: user?.city || '',
        address: user?.address || '',
        phone: user?.phone || '',
        email: user?.email || '',
    })
    const [errorValidation, setErrorValidation] = useState({
        nameError: false,
        cityError: false,
        addressError: false,
        phoneError: false,
        emailError: false,
    })
    const handleChangeUser = (type, e) => {
        const newUserSettings = { ...userSettings, [type]: e.target.value }
        setUserSettings(newUserSettings)
    }
    let fullCostValue = cart ? cart.reduce((acc, item) => acc + item.cost * (item.count || 1), 0) : 0;
    // Скролл в начало при нажатии на страницу
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const handleGoBack = () => {
        if (location.state && location.state.prevPath) {
            navigate(location.state.prevPath, { replace: true });
        } else {
            navigate(-1);
        }
    };

    const handlePlusOrMinus = (e, product) => {
        const newCart = cart.map(p => {
            if (p.id === product.id) {
                if (e === '+') {
                    product.count = +product.count + 1
                }
                if (e === '-' && product.count > 1) {
                    product.count = +product.count - 1
                }
                p.count = product.count
            }
            return p
        });
        if (user) {
            axios.put(`https://poghosty.ru/api/poghosty-authorizationAddOrRemoveCart.php`, { login: user.login, cart: JSON.stringify(newCart) })
                .then(response => {
                    if (response.data['error'] ? true : false) {
                        console.log(response.data['error'])
                    } else {
                        localStorage.setItem('cart', JSON.stringify(newCart))
                        setCart(newCart)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            localStorage.setItem('cart', JSON.stringify(newCart))
            setCart(newCart)
        }
    }
    const handleCountProductValue = (e, product) => {
        if (+e.target.value <= 0) {
            alert('Товаров не может быть меньше 1')
        } else {
            product.count = e.target.value || 1
            const newCart = cart.map(p => {
                if (p.id === product.id && +product.count > 0 && +e.target.value > 0) {
                    p.count = +product.count
                }
                return p
            });
            if (user) {
                axios.put(`https://poghosty.ru/api/poghosty-authorizationAddOrRemoveCart.php`, { login: user.login, cart: JSON.stringify(newCart) })
                    .then(response => {
                        if (response.data['error'] ? true : false) {
                            console.log(response.data['error'])
                        } else {
                            localStorage.setItem('cart', JSON.stringify(newCart))
                            setCart(newCart)
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                localStorage.setItem('cart', JSON.stringify(newCart))
                setCart(newCart)
            }

        }
    }
    const handleChangeSize = (opt, product) => {
        const newCart = cart.map((el, i) => {
            if (product.id === el.id) {
                el.choice = +opt
            }
            return el
        })
        if (user) {
            axios.put(`https://poghosty.ru/api/poghosty-authorizationAddOrRemoveCart.php`, { login: user.login, cart: JSON.stringify(newCart) })
                .then(response => {
                    if (response.data['error'] ? true : false) {
                        console.log(response.data['error'])
                    } else {
                        localStorage.setItem('cart', JSON.stringify(newCart))
                        setCart(newCart)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            localStorage.setItem('cart', JSON.stringify(newCart))
            setCart(newCart)
        }
    }
    const handleAddOrRemoveFromCart = (product, i) => {
        const item = document.querySelector(`.item${i}cart`)
        item.classList.add('fall')
        setTimeout(() => {
            removeFromCart(product, cart, setCart, user)
            item.classList.remove('fall')
        }, 700)
    }

    const handleUserPay = async () => {
        let value
        try {
            await axios.get(`https://poghosty.ru/api/poghosty-payCart.php`, {
                params: { login: user.login }
            })
                .then(response => {
                    if (response.data['pay']) {
                        value = response.data['pay']
                    }
                    if (response.data.error) {
                        console.log(response.data['error']);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } catch (error) {

        }


        try {
            await axios.put(`https://poghosty.ru/api/poghosty-updateUserCartSettings.php`, {
                name: userSettings.name, city: userSettings.city, address: userSettings.address, phone: userSettings.phone,
                email: userSettings.email, login: user.login, password: user.password
            })
                .then(response => {
                    if (response.data['error'] ? true : false) {
                        console.log(response.data['error'])
                    } else {
                        setUser(response.data['user'])
                        localStorage.setItem('user', JSON.stringify(response.data['user']))
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } catch (error) {

        }
        try {
            if (+value === +fullCostValue + 500) {
                // const now = getDate()
                // const newCart = JSON.parse(localStorage.getItem('cart')).map(product => product = { ...product, datePayment: now.formattedDate + ' ' + now.formattedTime })

                // axios.post(`https://poghosty.ru/api/poghosty-ordersAddFromCartUser.php`, { login: user.login, password: user.password, cart: newCart })
                //     .then(response => {
                //         if (response.data['error']) {
                //             console.log(response.data['error'])
                //         } else {
                //             setUser(response.data['user'])
                //             localStorage.setItem('user', JSON.stringify(response.data['user']))
                //         }
                //     })
                //     .catch(error => console.log(error))
            }
            else {
                console.log('no');
            }
        } catch (error) {

        }
    };
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const checkPaymentStatus = async () => {
            try {
                const response = await axios.get(`https://poghosty.ru/api/poghosty-payment-cb.php`);
                if (response.data.message) {
                    setPaymentStatus(response.data.message);
                }
            } catch (error) {
                console.error('Ошибка при получении статуса платежа:', error);
                setError('Ошибка при получении статуса платежа');
            }
        };

        // Запускаем проверку каждые 5 секунд
        const intervalId = setInterval(checkPaymentStatus, 5000);

        // Очищаем интервал при размонтировании компонента
        return () => clearInterval(intervalId);
    }, []);

    const handlePayNoUser = async () => {
        let value
        try {
            let obj = {}
            cart.forEach(product => obj[product.id] = product.count)
            await axios.get(`https://poghosty.ru/api/poghosty-payCartNoUser.php`, {
                params: { obj: obj }
            })
                .then(response => {
                    if (response.data['total']) {
                        value = response.data['total']
                    }
                    if (response.data.error) {
                        console.log(response.data['error']);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
        try {
            if (+value === +fullCostValue + 500) {
                axios.post(`https://poghosty.ru/api/poghosty-ordersAddFromCartNoUser.php`, { name: userSettings.name, city: userSettings.city, address: userSettings.address, phone: userSettings.phone, email: userSettings.email, cart: JSON.parse(localStorage.getItem('cart')) })
                    .then(response => {
                        if (response.data['error']) {
                            alert('Произошла ошибка покупки, попробуйте обновить страницу...')
                        } else {
                            axios.post('https://poghosty.ru/api/poghosty-YooKassa.php', {
                                amount: +fullCostValue + 500,
                                description: `Заказ №${response.data['order_id']['id']}`,
                                order_id: response.data['order_id']['id'],
                            }).then(res => {
                                window.location.href = res.data['payment_url'];
                                console.log(paymentStatus)
                            });
                        }
                    })
                    .catch(error => console.log(error))
            } else {
                alert('Произошла ошибка покупки, попробуйте обновить страницу...')
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleSendPay = () => {
        const nameRegex = /^[a-zA-Zа-яА-Я0-9]{4,22}$/;
        const cityRegex = /^[a-zA-Zа-яА-Я .-]{2,34}$/;
        const addressRegex = /^[a-zA-Zа-яА-Я0-9 .,-]{4,255}$/;
        const phoneRegex = /^(\+7)?[0-9]{10,11}$/;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,255}$/;

        ////////////////////////////////////////////////////////////////////////////////////////////
        const nameApprove = userSettings.name.match(nameRegex) !== null ? true : setErrorValidation({ ...errorValidation, nameError: true })
        const cityApprove = userSettings.city.match(cityRegex) !== null ? true : setErrorValidation({ ...errorValidation, cityError: true })
        const addressApprove = userSettings.address.match(addressRegex) !== null ? true : setErrorValidation({ ...errorValidation, addressError: true })
        const phoneApprove = userSettings.phone.match(phoneRegex) !== null ? true : setErrorValidation({ ...errorValidation, phoneError: true })
        const emailApprove = userSettings.email.match(emailRegex) !== null || userSettings.email === '' ? true : setErrorValidation({ ...errorValidation, emailError: true })
        if (nameApprove === true && cityApprove === true && addressApprove === true && phoneApprove === true && emailApprove === true) {

            if (user) {
                handleUserPay()
            } else {
                handlePayNoUser()
            }
        } else {

        }
    }
    const handleProductLink = (id) => {
        localStorage.setItem('lastPosScroll', window.scrollY);
        handleScrollToTop()
    }
    const isHasProducts = products.filter(prod => cart.some(el => el.id === prod.id))
    return (
        <div className=''>
            <h1 className="text-center main-text md:text-[64px] text-[32px] font-[700] mb-8 mt-10">Корзина</h1>

            <Link to='#' className="border border-white text-black bg-white p-2 rounded-md text-nowrap inline-flex items-center mb-4" onClick={() => handleGoBack()}><MdOutlineKeyboardBackspace className="text-[20px] text-black" /><b>Назад</b></Link>
            {
                cart.length > 0 && <section className="py-12 relative bg-white rounded-[20px] shadow-md shadow-white mb-8">
                    <div className="w-full max-w-7xl px-4 md:px-5 lg-6 mx-auto">
                        <div className="hidden lg:grid grid-cols-2 py-6">
                            <div className="flex items-center">
                                <p className="font-normal text-xl leading-8 text-gray-500">Продукт</p>
                            </div>
                            <p className="font-normal text-xl leading-8 text-gray-500 flex items-center justify-between">
                                <span className="w-full max-w-[140px] text-center">Стоимость доставки</span>
                                <span className="w-full max-w-[220px] text-center">Количество</span>
                                <span className="w-full max-w-[68px] text-center"></span>
                                <span className="w-full max-w-[140px] text-center">Сумма</span>
                            </p>
                        </div>

                        {
                            cart.length > 0 && cart.map((product, id) => {
                                return <div key={id} className={`item${id}cart grid grid-cols-1 lg:grid-cols-2 min-[550px]:gap-6 border-t border-gray-200 py-6`}>
                                    <div className="flex items-center flex-col min-[550px]:flex-row gap-3 min-[550px]:gap-6 w-full max-xl:justify-center max-xl:max-w-xl max-xl:mx-auto">
                                        <Link to={`/product/${product.id}`} className="img-box" onClick={() => { handleProductLink(product.id) }}>
                                            <img src={`${process.env.PUBLIC_URL}/img/products/${product.photo}/${product.photo}_1.jpg`} alt={`${product.photo}`} className="xl:w-[140px] rounded-xl object-cover" />
                                        </Link>
                                        <div className="pro-data w-full p-4 max-w-sm xl:hover:bg-gray-200/50 rounded-[20px]">
                                            <Link to={`/product/${product.id}`} className='flex' onClick={() => { handleProductLink(product.id) }}>
                                                <h5 className="font-semibold text-xl leading-8 text-black max-[550px]:text-center pr-4">{product.name} </h5>
                                                <p className="italic text-xl leading-8 text-black max-[550px]:text-center">{product.gender === 'm' ? 'Мужчины' : product.gender === 'w' ? 'Женщины' : ''}</p>
                                            </Link>
                                            <div className="flex max-w-[350px] relative">
                                                <p className="font-normal text-lg leading-8 text-gray-500 my-2 min-[550px]:my-3 max-[550px]:text-center pr-4">Размер: </p>
                                                <div className="relative flex w-[100px] text-[1.15rem] py-[2px] pl-[1em] border rounded-[.25em] cursor-pointer h-[40px] my-auto text-black bg-[#fff] " onClick={() => { setIdSelect(product.id); setClicked(!clicked) }}>
                                                    <p className="w-full my-auto">{product.choice} </p>
                                                    <IoIosArrowDown className="text-black w-[30px] mr-2 my-auto" />
                                                    {clicked && product.id === idSelect && <div className="z-[9995] absolute border border-b-transparent bg-white top-9 left-0 w-[100%]">
                                                        {product.size.split(', ').map((option, index) => {
                                                            return <div key={index} className="py-[2px] pl-[1em] h-[36px] border-b hover:bg-black hover:text-white" onClick={(e) => handleChangeSize(option, product)}>{option}</div>
                                                        })
                                                        }
                                                    </div>}
                                                </div>
                                            </div>
                                            <h6 className="font-medium text-lg leading-8 text-indigo-600  max-[550px]:text-center">Цена: {product.cost} ₽</h6>
                                            {!products.find(el => el.id === product.id) && <h6 className="font-medium text-lg leading-8 text-red-600  max-[550px]:text-center">Товар отсутствует</h6>}
                                        </div>
                                    </div>

                                    <div className="flex items-center flex-col min-[550px]:flex-row w-full max-xl:max-w-xl max-xl:mx-auto gap-3">
                                        <h6 className="font-manrope font-bold text-2xl leading-9 text-black w-full max-w-[176px] text-center">500 ₽</h6>
                                        <div className="flex items-center w-full mx-auto justify-center">
                                            <button className="group rounded-l-full px-6 py-[18px] border border-gray-200 flex items-center justify-center shadow-sm shadow-transparent transition-all duration-500 hover:shadow-gray-200 hover:border-gray-300 hover:bg-gray-50" onClick={() => handlePlusOrMinus('-', product)}>
                                                <FiMinus className="text-black" />
                                            </button>
                                            <input type="number" className="border-y border-gray-200 outline-none text-gray-900 font-semibold text-lg w-full max-w-[118px] min-w-[80px] placeholder:text-gray-900 py-[15px] text-center bg-transparent"
                                                value={product.count} onChange={(e) => handleCountProductValue(e, product)} />
                                            <button className="group rounded-r-full px-6 py-[18px] border border-gray-200 flex items-center justify-center shadow-sm shadow-transparent transition-all duration-500 hover:shadow-gray-200 hover:border-gray-300 hover:bg-gray-50" onClick={() => handlePlusOrMinus('+', product)}>
                                                <FiPlus className="text-black" />
                                            </button>

                                        </div>
                                        <button className="group ml-2 px-4 py-[18px] border border-transparent flex items-center justify-center shadow-sm shadow-transparent transition-all duration-500 hover:scale-110" onClick={() => handleAddOrRemoveFromCart(product, id)}>
                                            <TiDelete className="text-red-600 text-[36px]" />
                                        </button>
                                        <h6 className="text-indigo-600 font-manrope font-bold text-2xl leading-9 w-full max-w-[176px] text-center">{+product.cost * product.count} ₽</h6>

                                    </div>

                                </div>
                            })
                        }

                        <div className="bg-gray-50 rounded-xl p-6 w-full mb-8 max-lg:max-w-xl max-lg:mx-auto">
                            <div className="flex items-center justify-between w-full mb-6">
                                <p className="font-normal text-xl leading-8 text-gray-400">Стоимость одежды</p>
                                <h6 className="font-semibold text-xl leading-8 text-gray-900 text-nowrap">{fullCostValue} ₽</h6>
                            </div>
                            <div className="flex items-center justify-between w-full pb-6 border-b border-gray-200">
                                <p className="font-normal text-xl leading-8 text-gray-400">Стоимость доставки</p>
                                <h6 className="font-semibold text-xl leading-8 text-gray-900 text-nowrap">500 ₽</h6>
                            </div>
                            <div className="flex items-center justify-between w-full py-6">
                                <p className="font-manrope font-medium text-2xl leading-9 text-gray-900">Полная стоимость</p>
                                <h6 className="font-manrope font-medium text-2xl leading-9 text-indigo-500 text-nowrap">{fullCostValue + 500} ₽</h6>
                            </div>
                        </div>
                        <div className=" justify-center items-center flex">
                            <form action="" className={`max-h-[0px] transition-all duration-500 ease-in-out overflow-hidden ${address ? 'max-h-[1000px] px-4 py-6 shadow-[0px_0px_17px_5px_rgba(0,0,0,0.4)]' : ''} flex flex-col bg-black  rounded-md text-black lg:max-w-[70%] w-full `}>

                                <div className="flex items-center justify-center lg:justify-start w-[100%]">
                                    <div className="flex flex-col lg:w-[40%] w-[85%]">
                                        <label htmlFor="" className="text-white">Имя:</label>
                                        <input type="text" value={userSettings.name} className="border-2 lg:w-[90%] border-black mb-2 p-1 w-[100%]" onChange={(e) => { handleChangeUser('name', e); setErrorValidation({ ...errorValidation, nameError: false }) }} />
                                        {errorValidation.nameError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">Не допустимое имя</p>}
                                    </div>
                                    <div className='text-[18px] lg:block hidden'>
                                        <h1 className="text-white pt-4">Ваше имя</h1>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center lg:justify-start w-[100%]">
                                    <div className="flex flex-col lg:w-[40%] w-[85%]">
                                        <label htmlFor="" className="text-white">Город:</label>
                                        <input type="text" value={userSettings.city} className="border-2 lg:w-[90%] border-black mb-2 p-1 w-[100%]" onChange={(e) => { handleChangeUser('city', e); setErrorValidation({ ...errorValidation, cityError: false }) }} />
                                        {errorValidation.cityError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">Не допустимый город</p>}
                                    </div>
                                    <div className='text-[18px] lg:block hidden'>
                                        <h1 className="text-white pt-4">Укажите ваш город</h1>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center lg:justify-start w-[100%]">
                                    <div className="flex flex-col lg:w-[40%] w-[85%]">
                                        <label htmlFor="" className="text-white">Адрес доставки:</label>
                                        <input type="text" value={userSettings.address} className="border-2 lg:w-[90%] border-black mb-2 p-1 w-[100%]" onChange={(e) => { handleChangeUser('address', e); setErrorValidation({ ...errorValidation, addressError: false }) }} />
                                        {errorValidation.addressError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">Не допустимый адрес</p>}
                                    </div>
                                    <div className='text-[18px] lg:block hidden'>
                                        <h1 className="text-white pt-4">Укажите ваш адрес или адрес пункта выдачи</h1>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center lg:justify-start w-[100%]">
                                    <div className="flex flex-col lg:w-[40%] w-[85%]">
                                        <label htmlFor="" className="text-white">Телефон:</label>
                                        <input type="number" value={userSettings.phone} className="border-2 lg:w-[90%] border-black mb-2 p-1 w-[100%]" onChange={(e) => { handleChangeUser('phone', e); setErrorValidation({ ...errorValidation, phoneError: false }) }} />
                                        {errorValidation.phoneError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">Не допустимый телефон</p>}
                                    </div>
                                    <div className='text-[18px] lg:block hidden'>
                                        <h1 className="text-white pt-4">Ваш телефон для связи</h1>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center lg:justify-start w-[100%] pb-4">
                                    <div className="flex flex-col lg:w-[40%] w-[85%]">
                                        <label htmlFor="" className="text-white">Email(не обязательно):</label>
                                        <input type="email" value={userSettings.email} className="border-2 lg:w-[90%] border-black mb-2 p-1 w-[100%]" onChange={(e) => { handleChangeUser('email', e); setErrorValidation({ ...errorValidation, emailError: false }) }} />
                                        {errorValidation.emailError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">Не допустимая почта</p>}
                                    </div>
                                    <div className='text-[18px] lg:block hidden'>
                                        <h1 className="text-white pt-4">Ваша почта</h1>
                                    </div>
                                </div>

                            </form>
                        </div>

                        <div className="flex items-center flex-col sm:flex-row justify-center gap-3 mt-8">
                            {!address && isHasProducts.length === cart.length && <button className="rounded-full w-full max-w-[280px] py-4 text-center justify-center items-center bg-indigo-600 font-semibold text-lg text-white flex transition-all duration-500 hover:bg-indigo-700" onClick={() => setAddress(true)}>
                                К оформлению заказа
                                <IoIosArrowForward />
                            </button>}
                            {!address && isHasProducts.length !== cart.length && <p className="text-black">Для оформления заказа уберите из корзины отсутствующие товары</p>}
                            {address && <button className="rounded-full w-full max-w-[280px] py-4 text-center justify-center items-center bg-indigo-600 font-semibold text-lg text-white flex transition-all duration-500 hover:bg-indigo-700" onClick={() => handleSendPay()}>
                                Перейти к оплате
                                <IoIosArrowForward />
                            </button>}
                        </div>
                    </div>
                </section >

            }

            {
                cart.length === 0 && <div className="h-[40vh] flex items-center justify-center">
                    <h1 className="md:text-4xl text-2xl italic pb-20">Ваша корзина пуста...</h1>
                </div>
            }
        </div >
    );
};

export default Cart;