import Sort from ".//Sort";
import Filter from ".//Filter";

const SortAndFilter = (props) => {
    const { levels, sortName, setSortName, filterValues, setFilterValues, filterGender, setFilterGender, setPage } = props.props
    const propsSort = {
        sortName, setSortName
    }
    const propsFilter = {
        levels: levels,
        filterValues: filterValues,
        setFilterValues: setFilterValues,
        filterGender: filterGender,
        setFilterGender: setFilterGender,
        setPage: setPage,
    }
    return (
        <div>
            <div className="flex justify-between max-[360px]:px-2 p-6">
                <Sort props={propsSort} />
                <Filter props={propsFilter} />
            </div>
        </div >
    );
};

export default SortAndFilter;
