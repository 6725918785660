import { useEffect } from 'react';

import { motion } from 'framer-motion';
const Poghosty = () => {

    /////////////////////////////////////////////////////////////////////////////////
    function calcDistance(x1, y1, x2, y2) {
        return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
    }

    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const handler = (e) => {
        const maxX = document.documentElement.clientWidth - 260; // Учитываем ширину элемента
        const maxY = document.documentElement.clientHeight - 260; // Учитываем высоту элемента
        const minDistance = 150; // Минимальное расстояние
        const obj = document.querySelector('.poghosty'); // Находим элемент

        if (obj) { // Проверяем, существует ли элемент
            const objCoords = obj.getBoundingClientRect();
            const distance = calcDistance(e.clientX, e.clientY, objCoords.x + objCoords.width / 2, objCoords.y + objCoords.height / 2); // Центр элемента

            if (distance < minDistance) {
                // Устанавливаем новые случайные координаты
                obj.style.left = getRandomInt(0, maxX) + 'px';
                obj.style.top = getRandomInt(0, maxY) + 'px';
            }
        }
    };

    useEffect(() => {
        window.addEventListener('mousemove', handler); // Передаем функцию как обработчик
        return () => window.removeEventListener('mousemove', handler); // Удаляем обработчик при размонтировании
    }); // Пустой массив зависимостей
    /////////////////////////////////////////////////////////////////////////////////
    return (
        <div className=''>

            <motion.img
                initial={{ y: 0, x: 0 }}
                animate={{ y: getRandomInt(80, 100), x: getRandomInt(60, 80) }}
                transition={{
                    repeat: Infinity,
                    repeatDelay: 0.3,
                    repeatType: 'reverse',
                    duration: 2,
                    delay: 0.3,
                    ease: 'easeInOut'
                }}

                src={`${process.env.PUBLIC_URL}/img/resources/element_logo_black.png`} className="poghosty h-[140px] w-auto absolute top-0 left-[0px]" />

        </div>
    );
};

export default Poghosty;
