import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom'
import { IoWomanSharp } from "react-icons/io5";
const Womans = (props) => {
    const { womans, headerMove, setFilterValues, setFilterGender, setPage, handleScrollToTop } = props.props
    const handleFilterSeasonHeader = (season, gender) => {
        setFilterValues({ [season]: true })
        setFilterGender(gender)
    }

    const handleFilterTypeHeader = (season, type, gender) => {
        const newItem = {
            [season]: {
                [type]: true
            }
        }
        setFilterValues(newItem)
        setFilterGender(gender)
    }

    return (
        <li className='group/main cursor-pointer relative float-left flex items-center'>
            <Link to={'/'} className={`${headerMove ? `text-white` : 'text-black'}text-white hidden md:block  bg-transparent border-none hover:bg-transparent hover:scale-105 pr-2`} onClick={() => { setFilterGender('w'); setPage(1); handleScrollToTop() }}>Женщины</Link>
            <Link to={'/'} className={`${headerMove ? `text-white` : 'text-black'}text-white text-[32px] md:text-[18px] min-[400px]:block hidden bg-transparent border-none hover:bg-transparent hover:scale-105 pr-2`} onClick={() => { setFilterGender('w'); setPage(1); handleScrollToTop() }}><IoWomanSharp /></Link>
            <IoIosArrowDown className="xl:block hidden" />
            <ul className='xl:group-hover/main:block absolute hidden top-[38px] left-0 w-[300px] text-black'>
                {
                    womans && womans.filter((obj, i, arr) => i === arr.findIndex(el => obj.first_level_season === el.first_level_season)).map((first_level, i) => {
                        return <li key={i} className="group/item relative w-[100%]" onClick={(e) => { handleFilterSeasonHeader(first_level.first_level_season, 'w'); setPage(1) }}>

                            <div className='p-[8px] bg-white text-xl hover:bg-black  hover:text-white  rounded-[8px] border-b-2 border-r border-l border-b-gray-400 border-r-gray-400 border-l-gray-400 flex'>
                                <p className="mr-auto">{first_level.first_level_season}</p>
                                <ul className={`fg group-hover/item:block absolute hidden w-[300px] left-[300px] top-0 text-black`}>
                                    <li className='w-[100%] pointer-events-auto' >
                                        {
                                            womans && womans.filter((el, i, arr) => el.first_level_season === first_level.first_level_season)
                                                .filter((el, i, arr) => i === arr.findIndex(t => t.second_level_type === el.second_level_type)).map((type, i) => {
                                                    return <Link to={'/'} key={i} className={`w-[200px] p-[8px] bg-white block hover:bg-black hover:text-white  rounded-[8px] border-b-2 border-r border-l border-b-gray-400 border-r-gray-400 border-l-gray-400`} onClick={(e) => { e.stopPropagation(); handleFilterTypeHeader(first_level.first_level_season, type.second_level_type, 'w'); setPage(1); handleScrollToTop() }}>
                                                        <div className="flex flex-col">
                                                            <p className="float-start">{type.second_level_type}</p>
                                                        </div>
                                                    </Link>
                                                })
                                        }
                                    </li>
                                </ul>
                                <IoIosArrowForward className="my-auto" />
                            </div>
                        </li>
                    })
                }
            </ul>
        </li>
    );
};

export default Womans;
