import { GrFavorite } from "react-icons/gr";
import { FcLike } from "react-icons/fc";
import { useContext, useEffect, useState } from 'react';
import { addToCart, removeFromCart, addToFavorites, removeFromFavorites } from "../../utils/utils";
import ProductPhotos from './ProductPhotos'
import ProductInfo from './ProductInfo'
import Pages from './Pages'
import { Context } from "../../App";

const Products = (props) => {
    const { products, sortName, filterValues, filterGender, page, setPage } = props.props
    const [productsSortAndFilter, setProductsSortAndFilter] = useState([])
    // Выбор одежды
    const [idItem, setIdItem] = useState('')
    const [idSize, setIdSize] = useState('')
    const [errorSizeId, setErrorSizeId] = useState('')

    // Страницы
    const countPages = products && (products.length % 12 === 0 && products.length > 0 ? products.length / 12 : products.length < 12 && products.length > 0 ? 1 : Math.ceil(products.length / 12))
    // Корзина
    const { user, cart, setCart, favorites, setFavorites } = useContext(Context)
    // Скролл в начало при нажатии на страницу
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    const inCart = (id) => {
        return cart && cart.some(el => el.id === id)
    }
    const propsProductPhotos = (product) => {
        return ({
            id: product.id,
            photo: product.photo,
            countPhoto: product.countPhoto,
            handleScrollToTop: handleScrollToTop
        })
    }
    const propsProductInfo = (product) => {
        return ({
            id: product.id,
            name: product.name,
            gender: product.gender,
            cost: product.cost,
            size: product.size,
            handleScrollToTop: handleScrollToTop,
            idItem: idItem,
            idSize: idSize,
            setIdItem: setIdItem,
            setIdSize: setIdSize,
            errorSizeId: errorSizeId,
            cart: cart
        })
    }
    const propsPages = {
        page: page,
        setPage: setPage,
        countPages: countPages,
        handleScrollToTop: handleScrollToTop
    }
    const inFavorites = (id) => {
        return favorites && favorites.some(el => el.id === id)
    }
    const handleAddOrRemoveFromFavorites = (product) => {
        if (inFavorites(product.id)) {
            removeFromFavorites(product, favorites, setFavorites, user)
        } else {
            addToFavorites(product, favorites, setFavorites, user)
        }
    }
    const handleAddOrRemoveFromCart = (product) => {
        if (inCart(product.id)) {
            removeFromCart(product, cart, setCart, user)
        } else {
            if (idSize !== '' && idItem === product.id) {
                addToCart(product, cart, setCart, idSize, user)
            } else {
                setErrorSizeId(product.id)
            }
        }
    }
    useEffect(() => {
        const items = products.sort((a, b) => {
            switch (sortName) {
                case '': return 0
                case 'popularity': return b.popularity - a.popularity
                case 'decrease': return b.cost - a.cost
                case 'increase': return a.cost - b.cost
                case 'new': {
                    const dateA = a.date.split('-').map(Number);
                    const dateB = b.date.split('-').map(Number);

                    if (dateA[0] > dateB[0]) return -1;
                    if (dateA[0] < dateB[0]) return 1;
                    if (dateA[1] > dateB[1]) return -1;
                    if (dateA[1] < dateB[1]) return 1;
                    if (dateA[2] > dateB[2]) return -1;
                    if (dateA[2] < dateB[2]) return 1;

                    return 0;
                }
                case 'old': {
                    const dateA = a.date.split('-').map(Number);
                    const dateB = b.date.split('-').map(Number);

                    if (dateA[0] > dateB[0]) return 1;
                    if (dateA[0] < dateB[0]) return -1;
                    if (dateA[1] > dateB[1]) return 1;
                    if (dateA[1] < dateB[1]) return -1;
                    if (dateA[2] > dateB[2]) return 1;
                    if (dateA[2] < dateB[2]) return -1;

                    return 0;
                }
                default: return 0
            }
        }).filter(item => {
            return filterGender === '' ? true : item.gender === filterGender
        }).filter(item => Object.keys(filterValues).length > 0 ? (
            typeof filterValues[item.first_level_season] === 'object' ?
                Object.values(filterValues[item.first_level_season]).every(val => !val) ?
                    true :
                    filterValues[item.first_level_season][item.second_level_type] :
                filterValues[item.first_level_season] === true ?
                    true :
                    false
        ) : true
        );
        setProductsSortAndFilter(items)
    }, [filterValues, filterGender, sortName, products])
    return (
        <section className="pb-10">

            <div className=' text-gray-700 mb-4 w-full justify-center items-center ' style={{ display: 'grid', gap: '20px', gridTemplateColumns: `repeat(auto-fill, 290px)` }} onClick={(e) => {
                if (e.target.id !== 'size') { setIdItem(''); setIdSize('') }
                if (e.target.id !== 'cart') { setErrorSizeId('') }
            }}>
                {productsSortAndFilter && productsSortAndFilter.slice((page - 1) * 12, page * 12).map((product, i) => {
                    return <div key={i} className='bg-white rounded-[20px] relative flex flex-col max-h-[592px] shadow-md shadow-white'>
                        <ProductPhotos props={propsProductPhotos(product)} />
                        <button onClick={() => handleAddOrRemoveFromFavorites(product)} className='absolute top-0 right-0 z-[1000] decoration-0 p-4 hover:scale-105'>{inFavorites(product.id) ? <FcLike size={"36px"} /> : <GrFavorite size={"34px"} color='black' />}</button>
                        <div className='px-8 py-4'>
                            <ProductInfo props={propsProductInfo(product)} />
                            <button id='cart' onClick={() => handleAddOrRemoveFromCart(product)} className={`border-2 w-[100%] mx-auto p-1 rounded-[20px] mt-2 font-bold ${inCart(product.id) ? `bg-emerald-400` : `bg-black dark:hover:bg-gray-700 hover:bg-gray-800`} text-white hover:scale-105 hover:translate-y-[-1px] transition easy-in-out delay-100`}>{inCart(product.id) ? `Добавлено в корзину!` : `В корзину`}</button>
                        </div>
                    </div>
                })}
            </div>
            <Pages props={propsPages} />
        </section >
    );
};

export default Products;