import { useState } from "react";
import { BsFilterSquareFill } from "react-icons/bs";

const Filter = (props) => {
    const { levels, filterValues, setFilterValues, filterGender, setFilterGender, setPage } = props.props
    // Открыт ли попап
    const [popupFilterIsOpen, setPopupFilterIsOpen] = useState(false)
    // Уровни по гендеру
    const man = levels.filter((el) => el.gender === 'm')
    const woman = levels.filter((el) => el.gender === 'w')
    const [filter, setFilter] = useState(filterValues)
    const [filterG, setFilterG] = useState(filterGender)

    // Скролл в начало при нажатии на страницу
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    const openFilter = () => {
        setPopupFilterIsOpen(!popupFilterIsOpen)
        setFilter(filterValues)
    }
    const closeFilter = (event) => {
        event.preventDefault()
        setPopupFilterIsOpen(!popupFilterIsOpen)
    }

    const handleCheckbox = (e) => {
        setFilter({ ...filter, [e.target.name]: e.target.checked })
    }
    const handleCheckboxChild = (e, first) => {
        setFilter({ ...filter, [first]: { ...filter[first], [e.target.name]: e.target.checked } })
    }
    return (
        <div className="">
            <button className="flex items-center text-xl z-[9998]" onClick={openFilter}>
                <BsFilterSquareFill className="mr-[10px]" />
                <span>Фильтр</span>
            </button>
            {popupFilterIsOpen &&
                < div className="fixed top-0 left-0 w-[100%] h-[100%] bg-stone-700/[0.54] flex justify-center items-center z-[9999]" onClick={e => { if (e.target === e.currentTarget) { closeFilter(e) } }}>
                    <div className="bg-white rounded-[10px] shadow-md xl:w-[30%] w-[90%] text-black relative">
                        {<button className="absolute top-0 right-3 cursor-pointer text-4xl border-none bg-transparent" onClick={e => closeFilter(e)}>
                            &times;
                        </button>}
                        <h1 className="text-center text-4xl py-6 px-2">Фильтр одежды</h1>
                        {
                            filterG === '' &&
                            <div className="flex-col flex lg:flex-row justify-center items-center text-2xl pb-6">
                                <button className="p-4 hover:bg-[#f5f5f7]" onClick={() => setFilterG('m')}>Мужчины</button>
                                <button className="p-4 hover:bg-[#f5f5f7]" onClick={() => setFilterG('w')}>Женщины</button>
                            </div>
                        }
                        {
                            filterG !== '' &&
                            <div>
                                <h3 className="text-center text-2xl mb-2">{filterG === 'm' ? `Мужчины` : 'Женщины'}</h3>
                                <form className="flex flex-col px-8 pb-8 pt-2">
                                    {
                                        (filterG === 'm' ? man : woman).filter((el, i, arr) => i === arr.findIndex(t => t.firstLevel_season === el.firstLevel_season)).map((first_level, id) => {
                                            return first_level.gender === filterG && <div key={id}>
                                                <input type="checkbox" checked={filter[first_level.firstLevel_season]} className="mr-2 cursor-pointer" name={`${first_level.firstLevel_season}`} value={filter[first_level.firstLevel_season]} id={`${first_level.firstLevel_season}`} onChange={(e) => handleCheckbox(e)} />
                                                <label className="cursor-pointer" htmlFor={`${first_level.firstLevel_season}`}>{first_level.firstLevel_season}</label>
                                                {
                                                    (filterG === 'm' ? man : woman).filter(el => el.firstLevel_season === first_level.firstLevel_season).filter((el, i, arr) => i === arr.findIndex(t => t.secondLevel_season === el.secondLevel_season)).map((type, i) => {
                                                        return filter[first_level.firstLevel_season] && <div key={i} className="pl-4">
                                                            <input type="checkbox" checked={filter[first_level.firstLevel_season][type.secondLevel_season]} className="mr-2 cursor-pointer" name={`${type.secondLevel_season}`} value={filter[first_level.firstLevel_season][type.secondLevel_season]} id={`${type.idx}`} onChange={(e) => handleCheckboxChild(e, first_level.firstLevel_season)} />
                                                            <label className="cursor-pointer" htmlFor={`${type.idx}`}>{type.secondLevel_season}</label>
                                                        </div>
                                                    })
                                                }

                                            </div>
                                        })
                                    }
                                    {<div>
                                        <div className="w-[100%] flex justify-center mt-6">
                                            <button className={`border bg-red-500 p-2 w-[60%] md:w-[40%] hover:opacity-70 text-white`} onClick={(e) => { e.preventDefault(); setFilterValues(filter); setFilterGender(filterG); setPage(1); handleScrollToTop() }}>Применить</button>
                                        </div>
                                        <div className="w-[100%] flex justify-center mt-6">
                                            <button className="border-none bg-[#f5f5f7] p-2 w-[60%] md:w-[40%] hover:opacity-70 text-black" onClick={(e) => { e.preventDefault(); setFilterValues({}); setFilterGender(''); }}>Убрать фильтр</button>
                                        </div>
                                    </div>}
                                </form>
                            </div>
                        }
                    </div>
                </div >}
        </div >
    );
};

export default Filter;
