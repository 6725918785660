import { useState } from "react";
import { FaSortAmountDown } from "react-icons/fa";


const Sort = (props) => {
    const { sortName, setSortName } = props.props
    const [popupSortIsOpen, setPopupSortIsOpen] = useState(false)
    const [isAccepted, setIsAccepted] = useState(false)
    const [name, setName] = useState(sortName)
    //Sort items
    const [sortValue, setSortValue] = useState({
        popularity: false,
        decrease: false,
        increase: false,
        new: false,
        old: false,
    })
    const sortState = [
        {
            title: 'По популярности',
            option: { popularity: 'Более популярные' },
            name: 'popular'
        }, {
            title: 'По цене',
            option: { decrease: 'По убыванию', increase: 'По возрастанию' },
            name: 'cost'
        }, {
            title: 'По новизне',
            option: { new: 'Сначала новые', old: 'Сначала старые' },
            name: 'novelty'
        },
    ]

    // Скролл в начало при нажатии на страницу
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const openSort = () => {
        setPopupSortIsOpen(true)
        setSortValue({ ...sortValue, [sortName]: true })
    }
    const closeSort = () => {
        if (isAccepted) {
            setPopupSortIsOpen(false)
        } else {
            let defaultSortValue = {
                popularity: false,
                decrease: false,
                increase: false,
                new: false,
                old: false,
            }
            setSortValue(defaultSortValue)
            setPopupSortIsOpen(false)
        }
    }
    const handleChangeSort = (option) => {
        let defaultSortValue = {
            popularity: false,
            decrease: false,
            increase: false,
            new: false,
            old: false,
        }
        defaultSortValue[option] = true
        setSortValue(defaultSortValue)
        setName(option)
    }
    const handleAccept = (e) => {
        e.preventDefault();
        setSortName(name)
        setIsAccepted(true)
    }
    const handleRemoveSort = (e) => {
        let defaultSortValue = {
            popularity: false,
            decrease: false,
            increase: false,
            new: false,
            old: false,
        }
        e.preventDefault();
        setIsAccepted(false)
        setSortName('')
        setSortValue(defaultSortValue)
    }
    return (
        <div className="">
            <button className="flex items-center text-xl z-[9998]" onClick={() => openSort()}>
                <FaSortAmountDown className="mr-[10px]" />
                <span>Сортировать</span>
            </button>
            {popupSortIsOpen &&
                < div className="fixed top-0 left-0 w-[100%] h-[100%] bg-stone-700/[0.54] flex justify-center items-center z-[9999]" onClick={e => { if (e.target === e.currentTarget) { closeSort() } }}>
                    <div className="bg-white rounded-[10px] shadow-md xl:w-[30%] w-[90%] text-black relative">
                        {<button className="absolute top-0 right-3 cursor-pointer text-4xl border-none bg-transparent" onClick={e => closeSort(e)}>
                            &times;
                        </button>}
                        <h1 className="text-center text-4xl py-6">Сортировка одежды</h1>
                        {
                            <form className="flex flex-col px-8 pb-8 pt-2" >
                                {sortState.map((obj, i) => {
                                    return <div key={i} className="mb-2">
                                        <label className="text-black"><b>{obj.title}</b></label>
                                        {
                                            Object.keys(obj.option).map((options, i) => {
                                                return <div key={i}>
                                                    <input type="radio" name={options} className="mr-2 cursor-pointer" id={options} checked={sortValue[options]} onChange={() => handleChangeSort(options)} />
                                                    <label className="cursor-pointer text-black" htmlFor={options}>{obj.option[options]}</label>
                                                </div>
                                            })
                                        }
                                    </div>
                                })}

                                <div className="w-[100%] flex justify-center mt-6">
                                    <button className={`border ${Object.keys(sortValue).find(value => sortValue[value] === true) ? `bg-red-500` : `bg-gray-700`} p-2 md:w-[40%] w-[60%] hover:opacity-70 text-white`} onClick={(e) => { handleAccept(e); handleScrollToTop() }}>Применить</button>
                                </div>
                                <div className="w-[100%] flex justify-center mt-6">
                                    <button className="border-none bg-[#f5f5f7] p-2 md:w-[40%] w-[60%] hover:opacity-70 text-black" onClick={(e) => handleRemoveSort(e)}>Убрать фильтр</button>
                                </div>
                            </form>
                        }
                    </div>
                </div>}
        </div>
    );
};

export default Sort;
