import { useState, useContext } from "react";
// Icons
import { GrFavorite } from "react-icons/gr";
import { FcLike } from "react-icons/fc";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
// Компоненты
import ProductPhotos from "../Main/ProductPhotos";
import ProductInfo from "../Main/ProductInfo";
// Utils
import { addToCart, removeFromCart, addToFavorites, removeFromFavorites } from "../../utils/utils";
// Кнопка назад
import { useLocation, useNavigate, Link } from 'react-router-dom';
// Context
import { Context } from "../../App";

const Favorites = ({ products }) => {
    const { user, cart, setCart, favorites, setFavorites } = useContext(Context)

    const location = useLocation();
    const navigate = useNavigate();

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    const handleGoBack = () => {
        if (location.state && location.state.prevPath) {
            navigate(location.state.prevPath, { replace: true });
        } else {
            navigate(-1);
        }
    };

    const [idItem, setIdItem] = useState('')
    const [idSize, setIdSize] = useState('')
    const [errorSizeId, setErrorSizeId] = useState('')

    const inCart = (id) => {
        return cart && cart.some(el => el.id === id)
    }
    const inFavorites = (id) => {
        return favorites && favorites.some(el => el.id === id)
    }
    const handleAddOrRemoveFromFavorites = (product, i) => {
        if (inFavorites(product.id)) {
            const item = document.querySelector(`.item${i}favorites`)
            item.classList.add('fall')
            setTimeout(() => {
                removeFromFavorites(product, favorites, setFavorites, user)
                item.classList.remove('fall')
            }, 700)
        } else {
            addToFavorites(product, favorites, setFavorites, user)
        }
    }
    const handleAddOrRemoveFromCart = (product, i) => {
        if (inCart(product.id)) {
            removeFromCart(product, cart, setCart, user)
        } else {
            if (idSize !== '' && idItem === product.id) {
                addToCart(product, cart, setCart, idSize, user)
            } else {
                setErrorSizeId(product.id)
            }
        }
    }
    const propsProductPhotos = (product) => {
        return ({
            id: product.id,
            photo: product.photo,
            countPhoto: product.countPhoto,
            handleScrollToTop: handleScrollToTop,
        })
    }
    const propsProductInfo = (product) => {
        return ({
            id: product.id,
            name: product.name,
            gender: product.gender,
            cost: product.cost,
            size: product.size,
            handleScrollToTop: handleScrollToTop,
            idItem: idItem,
            idSize: idSize,
            setIdItem: setIdItem,
            setIdSize: setIdSize,
            errorSizeId: errorSizeId,
            cart: cart
        })
    }

    return (
        <div className="">

            <h1 className="text-center main-text md:text-[64px] text-[32px] font-[700] mb-8 mt-10">Избранное</h1>
            <Link to='#' className="border border-white text-black bg-white p-2 rounded-md text-nowrap inline-flex items-center mb-4" onClick={() => { handleGoBack(); handleScrollToTop() }}><MdOutlineKeyboardBackspace className="text-[20px] text-black" /><b>Назад</b></Link>
            {favorites.length !== 0 && <div className='justify-center items-center text-gray-700  mb-8 ' style={{ display: 'grid', gap: '20px', gridTemplateColumns: `repeat(auto-fill, 290px)` }} onClick={(e) => {
                if (e.target.id !== 'size') { setIdItem(''); setIdSize('') }
                if (e.target.id !== 'cart') { setErrorSizeId('') }
                // else { setErrorSize(''); setErrorSizeId('') }
            }}>
                {favorites.length !== 0 && favorites.map((product, i) => {
                    return <div key={i} className={`item${i}favorites bg-white rounded-[20px] relative max-h-[592px] flex flex-col shadow-md shadow-white`}>
                        <ProductPhotos props={propsProductPhotos(product)} />
                        <button onClick={() => handleAddOrRemoveFromFavorites(product, i)} className='absolute top-0 right-0 z-[1000] decoration-0 p-4 hover:scale-105'>{inFavorites(product.id) ? <FcLike size={"36px"} /> : <GrFavorite size={"34px"} color='black' />}</button>
                        <div className='px-8 py-4'>
                            <ProductInfo props={propsProductInfo(product)} />
                            {products.find(el => el.id === product.id) ? <button id='cart' onClick={() => handleAddOrRemoveFromCart(product, i)} className={`border-2 w-[100%] mx-auto p-1 rounded-[20px] mt-2 ${inCart(product.id) ? `bg-emerald-400` : `bg-black dark:hover:bg-gray-700 hover:bg-gray-800`} text-white hover:scale-105 hover:translate-y-[-1px] transition easy-in-out delay-100`}>{inCart(product.id) ? `Добавлено в корзину!` : `В корзину`}</button> : <p className="text-center">Товар отсутствует</p>}
                        </div>
                    </div>
                })}

            </div>}
            {
                favorites.length === 0 && <div className="h-[40vh] flex items-center justify-center">
                    <h1 className="md:text-4xl text-2xl text-center italic pb-20">Вы ничего не добавили в избранное...</h1>
                </div>
            }
        </div >
    );
};

export default Favorites;
