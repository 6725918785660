
import { Link } from 'react-router-dom'
import { BsCart2 } from "react-icons/bs";
import { GrFavorite } from "react-icons/gr";
import { GoPerson } from "react-icons/go";
import { GiHamburgerMenu } from "react-icons/gi";

const UserButtons = (props) => {
    const { handleScrollToTop, headerMove, openBurger, cart, favorites, setOpenBurger, openMyProfile, handleOpenMyProfile, setOpenMyProfile } = props.props

    return (
        <div className={`max-w-[300px] relative`}>
            <div className="hidden xl:block">
                <div className={`${headerMove ? `border-black` : `border-white`} border-2 rounded-[10px] p-1 hover:scale-105 transition ease-in-out delay-100`}>
                    <Link to={'/cart'} className='border-none bg-transparent p-0 hover:bg-transparent' onClick={() => handleScrollToTop()}>
                        <ul className={`flex justify-center cursor-pointer`}>
                            <div className="relative">
                                <BsCart2 size={'24px'} className="mr-[10px]" />
                                {cart.length !== 0 && <div className={`absolute top-[2px] flex justify-center items-center ${headerMove ? 'bg-black' : 'bg-white'} right-10 w-[24px] h-[24px] rounded-[50px]`}>
                                    <span className={`${headerMove ? 'text-white' : "text-black"}`}>{cart.length}</span>
                                </div>}
                            </div>
                            <li >
                                Корзина
                            </li>
                        </ul>
                    </Link>
                </div>
                <div className='flex justify-between py-2  relative'>

                    <Link to={'/favorites'} className='favorites mr-[15px]' onClick={() => handleScrollToTop()}>
                        <ul className={`${headerMove ? 'hover:border-black' : 'hover:border-white'} flex cursor-pointer hover:border-b-2 pb-[2px]  h-[30px]`}>
                            <div className="relative">
                                <GrFavorite size={'24px'} className={`mr-[10px] ${favorites.length > 0 ? 'text-red-600' : ''} z-[9999]`} />
                                {favorites.length !== 0 && <div className={`absolute top-[6px] z-[9997] right-[2px] flex justify-center items-center ${headerMove ? 'bg-black' : 'bg-white'} right-10 w-[24px] h-[24px] rounded-[50px]`}>
                                    <span className={`${headerMove ? 'text-white' : "text-black"} z-[9998]`}>{favorites.length}</span>
                                </div>}
                            </div>
                            <li className="">Избранное</li>
                        </ul>
                    </Link>
                    <button className='favorites' onClick={handleOpenMyProfile}>
                        <ul className={`${headerMove ? 'hover:border-black' : 'hover:border-white'} flex cursor-pointer  hover:border-b-2 pb-[2px]  h-[30px]`}>
                            <GoPerson size={'24px'} style={{ marginRight: '10px' }} />
                            <li className="text-nowrap"> Мой профиль</li>
                        </ul>
                    </button>

                </div>
            </div>
            <div className="xl:hidden h-[36px] flex-col items-center justify-center text-[32px]" >
                <GiHamburgerMenu className="" onClick={() => setOpenBurger(!openBurger)} />
                <GoPerson onClick={() => setOpenMyProfile(!openMyProfile)} />
            </div>
        </div >
    );
};

export default UserButtons;
