import { useState } from "react";
import axios from 'axios';
import Lottie from "lottie-react";
const Registration = (props) => {
    const { setUser, cart, setCart, favorites, setFavorites, getDate } = props.props
    const animationData = require('../../assets/success.json');
    // Ввод пользователя
    let [registration, setRegistration] = useState({
        name: '',
        login: '',
        password: ''
    })
    // Виден ли пароль
    const [isViewPassword, setIsViewPassword] = useState(false)
    const [errorValidation, setErrorValidation] = useState({
        nameError: false,
        loginError: false,
        passwordError: false,
    })
    const [successRegistration, setSuccessRegistration] = useState('')

    const handleUserFormChanger = (e, nameLoginPassword) => {
        setRegistration({ ...registration, [nameLoginPassword]: e.target.value });
    }

    // Отправка регистрации
    const handleSendRegistration = (event) => {
        event.preventDefault()
        const nameRegex = /^[a-zA-Zа-яА-Я0-9]{4,16}$/u;
        const loginRegex = /^[a-zA-Z0-9]{4,16}$/u;
        const passwordRegex = /^([^<>]{4,22})$/;
        //////////////////////////////////////////
        const nameApprove = registration.name.match(nameRegex) !== null ? true : 'Недопустимое имя'
        const loginApprove = registration.login.match(loginRegex) !== null ? true : 'Недопустимый логин'
        const passwordApprove = registration.password.match(passwordRegex) !== null ? true : 'Недопустимый пароль'
        if (nameApprove === true && loginApprove === true && passwordApprove === true) {
            const now = getDate()
            axios.post(`https://poghosty.ru/api/poghosty-registration.php`, { name: registration.name, login: registration.login, password: registration.password, registrationDate: now.formattedDate, lastDateVisit: now.formattedDate, lastTimeVisit: now.formattedTime })
                .then(response => {
                    if (response.data['error']) {
                        const newError = { ...errorValidation, [response.data['type']]: response.data['error'] }
                        setErrorValidation(newError)
                    }
                    if (response.data['success']) {
                        setSuccessRegistration(response.data['success'])
                        setTimeout(() => {
                            axios.post(`https://poghosty.ru/api/poghosty-authorization.php`, { login: registration.login, password: registration.password, cart: cart, favorites: favorites })
                                .then(response => {
                                    if (response.data['error'] ? true : false) {
                                        const newError = { ...errorValidation, [response.data['type']]: response.data['error'] }
                                        setErrorValidation(newError)
                                    } else {
                                        localStorage.setItem('user', JSON.stringify(response.data['user']))
                                        setUser(response.data['user'])
                                        /////////////////////////////////////////////////////////////////////
                                        localStorage.setItem('favorites', response.data['user']['favorites'])
                                        setFavorites(JSON.parse(response.data['user']['favorites']))
                                        /////////////////////////////////////////////////////////////////////
                                        localStorage.setItem('cart', response.data['user']['cart'])
                                        setCart(JSON.parse(response.data['user']['cart']))
                                    }

                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }, 2000)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            const newError = { ...errorValidation, nameError: nameApprove === true ? false : nameApprove, loginError: loginApprove === true ? false : loginApprove, passwordError: passwordApprove === true ? false : passwordApprove }
            setErrorValidation(newError)
        }

    }
    return (
        <div className="w-[100%] h-[100%] bg-[#1e1e1c] p-2">
            {
                successRegistration === '' && <form action="" className="flex flex-col items-center">
                    <label className={`text-white w-[50%]`} htmlFor={'userNameRegistr'}> Ваше имя:</label>
                    <input className={`w-[50%] border-none outline-none pl-1 ${errorValidation.nameError ? `shadow-[inset_0_0_2px_3px_rgba(201,21,21,0.4)]` : ''}`} value={registration.name} type="text" name={'userNameRegistr'} onChange={(e) => { handleUserFormChanger(e, 'name'); setErrorValidation({ ...errorValidation, nameError: false }) }}></input>
                    {errorValidation.nameError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">{errorValidation.nameError}</p>}
                    <label className="text-white w-[50%]" htmlFor={'loginRegistr'}> Логин: </label>
                    <input className={`w-[50%] border-none outline-none pl-1 ${errorValidation.loginError ? `shadow-[inset_0_0_2px_3px_rgba(201,21,21,0.4)]` : ''}`} value={registration.login} type="text" name={'loginRegistr'} onChange={(e) => { handleUserFormChanger(e, 'login'); setErrorValidation({ ...errorValidation, loginError: false }) }}></input>
                    {errorValidation.loginError && <p id='errorSize' className="text-[14px] text-wrap text-center leading-5 text-red-500">{errorValidation.loginError}</p>}
                    <label className="text-white w-[50%]" htmlFor="passwordRegistr"> Пароль:</label>
                    <div className="w-1/2 relative">
                        <input className={`w-[100%] border-none outline-none pl-1 ${errorValidation.passwordError ? `shadow-[inset_0_0_2px_3px_rgba(201,21,21,0.4)]` : ''}`} value={registration.password} type={`${isViewPassword ? "text" : "password"}`} name="passwordRegistr" onChange={(e) => { handleUserFormChanger(e, 'password'); setErrorValidation({ ...errorValidation, passwordError: false }) }} />
                        {errorValidation.passwordError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">{errorValidation.passwordError}</p>}
                        <button
                            className="absolute h-[20px] top-[4px] right-[-10px] inset-y-0 flex items-center px-4 text-gray-600"
                            onClick={(e) => { e.preventDefault(); setIsViewPassword(!isViewPassword) }}
                        >
                            {isViewPassword ? <svg
                                xmlns="https://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                            </svg> :

                                <svg
                                    xmlns="https://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                    />
                                </svg>
                            }</button>
                    </div>
                    <button className="text-white border-2 rounded-[20px] px-3 py-2 my-4" onClick={handleSendRegistration}>Зарегистрироваться</button>
                </form>
            }
            {
                successRegistration === 'Вы успешно зарегистрировались!' && <div className="bg-[#1e1e1c] h-[300px] w-full rounded-[10px] flex flex-col justify-center items-center text-white text-center">
                    <Lottie animationData={animationData} loop={false} className="w-[100px] h-auto" />
                    <h1>{successRegistration}</h1>
                </div>
            }
        </div>
    );
};

export default Registration;
