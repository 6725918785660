const Pages = (props) => {
    const { page, setPage, countPages, handleScrollToTop } = props.props
    // Нажать на страницу
    const handleClickPage = (page) => {
        localStorage.setItem('page', page)
        setPage(page)
    }
    const handleChangePage = (changer) => {
        if (changer === '+') {
            if (page !== countPages) {
                handleClickPage(page + 1);
                handleScrollToTop()
            } else {
                handleClickPage(page)
            }
        }
        if (changer === '-') {
            if (page !== 1) {
                handleClickPage(page - 1)
            } else {
                handleClickPage(page)
            }
        }
    }

    return (
        <div className='py-8'>
            <button className={`border-2 w-[50px] h-[50px] mr-2 ${page === 1 ? `hover:cursor-default opacity-20` : ''}`} onClick={() => handleChangePage('-')}>←</button>
            {
                Array.from({ length: countPages }).map((_, i) => {
                    return <button key={i} className={`border-2 w-[50px] h-[50px] mr-2 ${(i + 1) === page ? `bg-white text-black` : `bg-transparent`}`} onClick={() => handleClickPage(i + 1)}> {i + 1}</button>
                })
            }

            <button className={`border-2 w-[50px] h-[50px] ${page === countPages ? `hover:cursor-default opacity-20` : ''}`} onClick={() => handleChangePage('+')}>→</button>
        </div>
    );
};

export default Pages;
