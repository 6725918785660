import Lottie from "lottie-react";
const Success = () => {
    const animationData = require('../../assets/success.json');
    return (
        <div className="w-full h-[300px] text-black bg-white rounded-md">
            <div className="text-[36px] p-4 flex justify-center items-center w-full">
                <Lottie animationData={animationData} loop={false} className="w-[100px] h-auto" />
                <h1>Заказ успешно оплачен!</h1>
            </div>
            <div className="w-full flex flex-col items-center justify-center">
                <p>Мы отправим Вам код для отслеживания посылки, по смс и email(при наличии), которые вы указали при оформлении заказа.</p>
                <p> Если Вы зарегистрированы на нашем сайте, то можете посмотреть статус вашего заказа в разделе "Мой профиль" → "Мои заказы". </p>
                <p> По всем вопросам обращаться по тел: +7(925)317-47-14 (Максим) </p>
            </div>
        </div>
    );
};

export default Success;
