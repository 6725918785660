import React, { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom';
import axios from 'axios'
import Favorites from './components/Header/Favorites'
import HeaderApp from './components/Header/HeaderApp'
import Cart from './components/Header/Cart'
import Admin from './components/Header/Admin'
import Orders from './components/Header/Orders'
import Settings from './components/Header/Settings'
import Help from './components/Header/Help'
import SortAndFilter from './components/Main/SortAndFilter'
import Section from './components/Main/Section'
import Products from './components/Main/Products'
import Product from './components/Main/Product'
import Success from './components/Main/Success'
import Footer from './components/Footer/Footer'

export const Context = React.createContext()
function App() {
  // Все продукты
  const [products, setProducts] = useState([])
  const [productsAdmin, setProductsAdmin] = useState([])
  const [usersAdmin, setUsersAdmin] = useState([])
  // Поиск
  const [searchValue, setSearchValue] = useState('')
  // Сезон, тип, gender
  const [levels, setLevels] = useState([])
  // Сортировка
  const [sortName, setSortName] = useState('')
  // Фильтр
  const [filterValues, setFilterValues] = useState({});
  const [filterGender, setFilterGender] = useState('')
  // Страница
  const [page, setPage] = useState(1)
  // Корзина и избранное
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : [])
  const [favorites, setFavorites] = useState(JSON.parse(localStorage.getItem('favorites')) ? JSON.parse(localStorage.getItem('favorites')) : [])

  const getDate = () => {
    const today = new Date(); // Получаем текущую дату

    // Извлекаем год, месяц и день
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const day = String(today.getDate()).padStart(2, '0');
    const hours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const seconds = String(today.getSeconds()).padStart(2, '0');
    // Форматируем дату в нужный формат
    return {
      formattedDate: `${year}-${month}-${day}`,
      formattedTime: `${hours}:${minutes}:${seconds}`
    }
  }
  // Передача токена
  const [user, setUser] = useState(() => {
    if (localStorage.getItem('user')) {

      const user = JSON.parse(localStorage.getItem('user'))
      const now = getDate()
      axios.post(`https://poghosty.ru/api/poghosty-getUser.php`, { login: user.login, password: user.password, cart: cart, favorites: favorites, lastDateVisit: now.formattedDate, lastTimeVisit: now.formattedTime })
        .then(response => {
          if (response.data['error'] ? true : false) {
            localStorage.removeItem('user')
            setUser(null)
          } else {
            localStorage.setItem('user', JSON.stringify(response.data['user']))
            /////////////////////////////////////////////////////////////////////
            localStorage.setItem('favorites', response.data['user']['favorites'])
            setFavorites(JSON.parse(response.data['user']['favorites']))
            /////////////////////////////////////////////////////////////////////
            localStorage.setItem('cart', response.data['user']['cart'])
            setCart(JSON.parse(response.data['user']['cart']))
          }
        })
        .catch(error => {
          console.log(error)
        });
      const storedUser = localStorage.getItem('user');
      return storedUser ? JSON.parse(storedUser) : null;
    } else {
      return null
    }
  })
  useEffect(() => {
    axios.get(`https://poghosty.ru/api/poghosty-products.php`)
      .then(response => {
        setProductsAdmin(response.data)
        const sizesAndDateGroupProducts = response.data.reduce((accumulator, current) => {
          const nameProduct = accumulator.find(item => item.name === current.name);
          const costProduct = accumulator.find(item => item.cost === current.cost);
          if (nameProduct && costProduct) {
            nameProduct.size = (nameProduct.size + ', ' + current.size).split(' ').sort((a, b) => a - b).join(' ')
            const date = nameProduct.date.split('-')[0] > current.date.split('-')[0] ||
              (nameProduct.date.split('-')[0] >= current.date.split('-')[0] && nameProduct.date.split('-')[1] >= current.date.split('-')[1] &&
                nameProduct.date.split('-')[2] >= current.date.split('-')[2])
            if (!date) {
              nameProduct.date = current.date
            }
          } else {
            accumulator.push({ ...current });
          }
          return accumulator;
        }, [])
        setProducts(sizesAndDateGroupProducts)
        setLevels(response.data.map(el => {
          return {
            firstLevel_season: el.first_level_season,
            secondLevel_season: el.second_level_type,
            gender: el.gender,
            idx: el.id
          }
        }))
      })
      .catch(error => {
        console.log(error);
      });
    axios.get(`https://poghosty.ru/api/poghosty-adminGetOrders.php`)
      .then(response => {
        setUsersAdmin(response.data['result'])
      })
      .catch(error => {
        console.log(error);
      });
  }, [])
  useEffect(() => {
    setFavorites(favorites)
  }, [favorites])
  const propsHeaderApp = {
    products: products,
    searchValue: searchValue,
    setSearchValue: setSearchValue,
    setSortName: setSortName,
    setFilterValues: setFilterValues,
    setFilterGender: setFilterGender,
    setPage: setPage,
    getDate: getDate
  }
  const propsSortAndFilter = {
    levels: levels,
    sortName: sortName,
    setSortName: setSortName,
    filterValues: filterValues,
    setFilterValues: setFilterValues,
    filterGender: filterGender,
    setFilterGender: setFilterGender,
    setPage: setPage
  }
  const propsProducts = {
    products: products,
    sortName: sortName,
    filterValues: filterValues,
    filterGender: filterGender,
    page: page,
    setPage: setPage,
  }

  const Home = () => {
    return (
      <div>
        {sortName === '' && filterGender === '' && page === 1 && < Section />}
        <div><h1 className='text-center main-text md:text-[64px] text-[32px] font-[700] mb-8 mt-10'>{filterGender === 'm' ? 'Мужская одежда' : filterGender === 'w' ? 'Женская одежда' : ''}</h1></div>
        <SortAndFilter props={propsSortAndFilter} />
        <Products props={propsProducts} />
      </div>
    );
  };
  return (
    <div className='app'>

      <div className='container xl mx-auto px-4 py-4 relative'>

        <Context.Provider value={{ user, setUser, cart, setCart, favorites, setFavorites, products }}>
          <HeaderApp props={propsHeaderApp} />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path={`/product/:id`} element={<Product />} />
            <Route path='/cart' element={<Cart setUser={setUser} getDate={getDate} products={products} />} />
            <Route path='/favorites' element={<Favorites products={products} />} />
            <Route path='/success' element={<Success />} />
            {user &&
              <>
                <Route path='/orders' element={<Orders orders={user.orders ? JSON.parse(user.orders) : []} />} />
                <Route path='/settings' element={<Settings user={user} setUser={setUser} />} />
              </>}
            <Route path='/help' element={<Help />} />
            {user && user.name === 'adminPoghosty' && user.id === 62 && user.password === '$2y$10$C2sZwPM9hQ9fvQKus/YZnenap5gkoUFke8Qbg6AeJ0Y5qOnAcIxqO' && <Route path='/adminPoghosty' element={<Admin products={productsAdmin} users={usersAdmin} />} />}
          </Routes>
        </Context.Provider>
      </div >
      <Footer />
    </div >
  );
}

export default App;
