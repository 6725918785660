import { useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios from 'axios'
const Settings = (props) => {

    const [userSettings, setUserSettings] = useState({
        name: props?.user?.name,
        city: props?.user?.city,
        address: props?.user?.address,
        phone: props?.user?.phone,
        email: props?.user?.email,
        login: props?.user?.login,
        password: '',
    })
    const [errorValidation, setErrorValidation] = useState({
        nameError: false,
        cityError: false,
        addressError: false,
        phoneError: false,
        emailError: false,
        loginError: false,
        passwordError: false,
    })
    const [success, setSuccess] = useState('')
    const location = useLocation();
    const navigate = useNavigate();
    const handleGoBack = () => {
        if (location.state && location.state.prevPath) {
            navigate(location.state.prevPath, { replace: true });
        } else {
            navigate(-1);
        }
    };
    const handleChangeUser = (type, e) => {
        const newUserSettings = { ...userSettings, [type]: e.target.value }
        setUserSettings(newUserSettings)
    }
    const handleSendUpdate = () => {
        const result = window.confirm("Вы уверены, что хотите изменить ваши данные?");
        if (result) {
            const nameRegex = /^[a-zA-Zа-яА-Я0-9]{4,22}$/;
            const cityRegex = /^[a-zA-Zа-яА-Я .-]{4,34}$/;
            const addressRegex = /^[a-zA-Zа-яА-Я0-9 .,-]{4,255}$/;
            const phoneRegex = /^(\+7)?[0-9]{10,11}$/;
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,255}$/;
            const loginRegex = /^[a-zA-Z0-9]{4,16}$/;
            const passwordRegex = /^([^<>]{4,22})$/;
            ////////////////////////////////////////////////////////////////////////////////////////////
            const nameApprove = userSettings.name.match(nameRegex) !== null ? true : setErrorValidation({ ...errorValidation, nameError: true })
            const cityApprove = userSettings.city.match(cityRegex) !== null || userSettings.city === '' ? true : setErrorValidation({ ...errorValidation, cityError: true })
            const addressApprove = userSettings.address.match(addressRegex) !== null || userSettings.address === '' ? true : setErrorValidation({ ...errorValidation, addressError: true })
            const phoneApprove = userSettings.phone.match(phoneRegex) !== null || userSettings.phone === '' ? true : setErrorValidation({ ...errorValidation, phoneError: true })
            const emailApprove = userSettings.email.match(emailRegex) !== null || userSettings.email === '' ? true : setErrorValidation({ ...errorValidation, emailError: true })
            const loginApprove = userSettings.login.match(loginRegex) !== null ? true : setErrorValidation({ ...errorValidation, loginError: true })
            const passwordApprove = userSettings.password.match(passwordRegex) !== null || userSettings.password === '' ? true : setErrorValidation({ ...errorValidation, passwordError: true })
            if (nameApprove === true && cityApprove === true && addressApprove === true && phoneApprove === true && emailApprove === true && loginApprove === true && passwordApprove === true) {
                axios.put(`https://poghosty.ru/api/poghosty-updateUserSettings.php`, {
                    name: userSettings.name, city: userSettings.city, address: userSettings.address, phone: userSettings.phone,
                    email: userSettings.email, login: userSettings.login, password: userSettings.password, userLogin: props.user.login, userPassword: props.user.password
                })
                    .then(response => {
                        if (response.data['error'] ? true : false) {
                            console.log(response.data['error'])
                        } else if (response.data['existLogin'] ? true : false) {
                            setErrorValidation({ ...errorValidation, loginError: response.data['existLogin'] })
                        } else {
                            setSuccess(response.data['message'])
                            props.setUser(response.data['user'])
                            localStorage.setItem('user', JSON.stringify(response.data['user']))
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {

            }
        } else {
            setSuccess('')
        }
    }
    const deleteUser = (e) => {
        e.preventDefault()
        const result = window.confirm("Вы уверены, что хотите удалить аккаунт?");
        if (result) {
            axios.delete(`https://poghosty.ru/api/poghosty-deleteUser.php`, {
                params: {
                    login: props.user.login, password: props.user.password
                }
            })
                .then(response => {
                    if (response.data['error'] ? true : false) {
                        console.log(response.data['error'])
                    } else {
                        localStorage.removeItem('user')
                        props.setUser(null)
                        window.location.href = '/';
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            setSuccess('')
        }
    }
    return (
        <>
            <Link to='#' className="border border-white text-black bg-white p-2 rounded-md text-nowrap inline-flex items-center mb-4" onClick={() => handleGoBack()}><MdOutlineKeyboardBackspace className="text-[20px] text-black" /><b>Назад</b></Link>
            <div className="text-black w-[100%] p-8 rounded-md bg-white">
                <h1 className="text-center text-[36px] mb-6">Настройка профиля</h1>

                <form action="" className="flex flex-col bg-black px-4 py-6 rounded-md text-black lg:max-w-[70%] w-full shadow-[0px_0px_17px_5px_rgba(0,0,0,0.4)]">
                    <div className="flex items-center justify-center lg:justify-start w-[100%]">
                        <div className="flex flex-col lg:w-[40%] w-[85%]">
                            <label htmlFor="" className="text-white">Имя:</label>
                            <input type="text" value={userSettings.name} className="border-2 lg:w-[90%] border-black mb-2 p-1 w-[100%]" onChange={(e) => { handleChangeUser('name', e); setErrorValidation({ ...errorValidation, nameError: false }); setSuccess('') }} />
                            {errorValidation.nameError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">Не допустимое имя</p>}
                        </div>
                        <div className='text-[18px] lg:block hidden'>
                            <h1 className="text-white pt-4">Изменить имя</h1>
                        </div>
                    </div>
                    <div className="flex items-center justify-center lg:justify-start w-[100%]">
                        <div className="flex flex-col lg:w-[40%] w-[85%]">
                            <label htmlFor="" className="text-white">Город:</label>
                            <input type="text" value={userSettings.city} className="border-2 lg:w-[90%] border-black mb-2 p-1 w-[100%]" onChange={(e) => { handleChangeUser('city', e); setErrorValidation({ ...errorValidation, cityError: false }); setSuccess('') }} />
                            {errorValidation.cityError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">Не допустимый город</p>}
                        </div>
                        <div className='text-[18px] lg:block hidden'>
                            <h1 className="text-white pt-4">Укажите ваш город</h1>
                        </div>
                    </div>
                    <div className="flex items-center justify-center lg:justify-start w-[100%]">
                        <div className="flex flex-col lg:w-[40%] w-[85%]">
                            <label htmlFor="" className="text-white">Адрес доставки:</label>
                            <input type="text" value={userSettings.address} className="border-2 lg:w-[90%] border-black mb-2 p-1 w-[100%]" onChange={(e) => { handleChangeUser('address', e); setErrorValidation({ ...errorValidation, addressError: false }); setSuccess('') }} />
                            {errorValidation.addressError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">Не допустимый адрес</p>}
                        </div>
                        <div className='text-[18px] lg:block hidden'>
                            <h1 className="text-white pt-4">Укажите ваш адрес или адрес пункта выдачи</h1>
                        </div>
                    </div>
                    <div className="flex items-center justify-center lg:justify-start w-[100%]">
                        <div className="flex flex-col lg:w-[40%] w-[85%]">
                            <label htmlFor="" className="text-white">Телефон:</label>
                            <input type="number" value={userSettings.phone} className="border-2 lg:w-[90%] border-black mb-2 p-1 w-[100%]" onChange={(e) => { handleChangeUser('phone', e); setErrorValidation({ ...errorValidation, phoneError: false }); setSuccess('') }} />
                            {errorValidation.phoneError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">Не допустимый телефон</p>}
                        </div>
                        <div className='text-[18px] lg:block hidden'>
                            <h1 className="text-white pt-4">Ваш телефон для связи</h1>
                        </div>
                    </div>
                    <div className="flex items-center justify-center lg:justify-start w-[100%]">
                        <div className="flex flex-col lg:w-[40%] w-[85%]">
                            <label htmlFor="" className="text-white">Email:</label>
                            <input type="email" value={userSettings.email} className="border-2 lg:w-[90%] border-black mb-2 p-1 w-[100%]" onChange={(e) => { handleChangeUser('email', e); setErrorValidation({ ...errorValidation, emailError: false }); setSuccess('') }} />
                            {errorValidation.emailError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">Не допустимая почта</p>}
                        </div>
                        <div className='text-[18px] lg:block hidden'>
                            <h1 className="text-white pt-4">Ваша почта</h1>
                        </div>
                    </div>
                    <div className="flex items-center justify-center lg:justify-start w-[100%]">
                        <div className="flex flex-col lg:w-[40%] w-[85%]">
                            <label htmlFor="" className="text-white">Логин:</label>
                            <input type="text" value={userSettings.login} className="border-2 lg:w-[90%] border-black mb-2 p-1 w-[100%]" onChange={(e) => { handleChangeUser('login', e); setErrorValidation({ ...errorValidation, loginError: false }); setSuccess('') }} />
                            {errorValidation.loginError === 'Пользователь с таким логином уже существует!' ? <p id='errorSize' className="text-[14px] text-wrap text-red-500">Пользователь с таким логином уже существует!</p> :
                                errorValidation.loginError === true && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">Не допустимый логин!</p>}

                        </div>
                        <div className='text-[18px] lg:block hidden'>
                            <h1 className="text-white pt-4">Изменить логин</h1>
                        </div>
                    </div>
                    <div className="flex items-center justify-center lg:justify-start w-[100%] pb-4">
                        <div className="flex flex-col lg:w-[40%] w-[85%]">
                            <label htmlFor="" className="text-white">Новый пароль:</label>
                            <input type="text" value={userSettings.password} className="border-2 lg:w-[90%] border-black mb-2 p-1 w-[100%]" onChange={(e) => { handleChangeUser('password', e); setErrorValidation({ ...errorValidation, passwordError: false }); setSuccess('') }} />
                            {errorValidation.passwordError && <p id='errorSize' className="text-[14px] text-nowrap text-red-500">Не допустимый пароль</p>}
                        </div>
                        <div className='text-[18px] lg:block hidden'>
                            <h1 className="text-white pt-4">Изменить пароль</h1>
                        </div>
                    </div>

                    <div className="flex max-[490px]:flex-col w-full ">
                        <button className="hover:text-white border-white border text-[20px] max-[490px]:mx-4 mx-7 p-4 mt-2 rounded-md bg-white text-black hover:bg-emerald-400 mb-2" onClick={(e) => { e.preventDefault(); handleSendUpdate() }}>Сохранить изменения</button>
                        <button className="text-white border hover:bg-red-600 hover:text-black border-red-600 p-4 text-[20px] mt-2 mb-2 rounded-md max-[490px]:mx-4 mx-7" onClick={(e) => deleteUser(e)}>Удалить аккаунт</button>
                    </div>
                    {success === 'Изменения прошли успешно!' && < p className="text-emerald-400 text-center">{success}</p>}
                </form>
            </div >
        </>
    );
};

export default Settings;
